// screens/LoginScreen.js
import React, { useContext, useState, useEffect } from "react";
import { authenticateUser, authenticateUserWeb, authenticateUserWeb2FA } from "../api/auth";
import { theme, navigationRoutes } from "../utils/variables";
import { AuthContext } from "../App";
import { Autocomplete, Box, Button, Container, Grid, ImageListItem, Tooltip } from "@mui/material";
import styles from "../components/MyInputs/styles";
import MySnackBar from "../components/MyInputs/MySnackBar";
import imageLogo from "../assets/icon.png";
import MyCard from "../components/MyInputs/MyCard";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { HelpOutline, Lock, Login, PersonAdd } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MyCardTitle from "../components/MyInputs/MyCardTitle";
import MySelectList from "../components/MyInputs/MySelectList";
import { Link, useNavigate } from "react-router-dom";
import MyLoadingBar from "../components/MyLoadingBar";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_KEY } from "../api/api";

const LoginScreen = ({ navigation }) => {
  const { strings, setAppLanguage, setInterviewer, setIsAuthenticated } = useContext(AuthContext);
  // const [username, setUsername] = useState("james254mumo@gmail.com");
  // const [password, setPassword] = useState("db4cxW6SryjqW7T");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState();
  const [enterTokenStep, setEnterTokenStep] = useState(false);
  const [language, setLanguage] = useState("en");
  const [isReCaptchaSet, setIsReCaptchaSet] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const languages = [
    { key: "en", value: "English" },
    { key: "fr", value: "French" },
    { key: "pt", value: "Portuguese" },
  ];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");
  const onDismissProgressBar = () => {
    setProgressBarVisible(false);
  };

  const handleLogin = async () => {
    if(!isReCaptchaSet){
      setProgressBarMessage(strings.dataValidations.solveCaptcha);
      setProgressBarSeverity("error");
      setProgressBarVisible(true);
      return
    }
    try {
      setLoading(true);

      setProgressBarMessage(strings.dataValidations.pleaseWait);
      setProgressBarSeverity("info");
      setProgressBarVisible(true);
      if(userId){
        if (token.length < 6) {
          setProgressBarMessage(strings.login.tokenInvalid);
          setProgressBarSeverity("error");
          setProgressBarVisible(true);
          return;
        }
        try{
          const {access, interviewer} = await authenticateUserWeb2FA(username.trim().toLowerCase(), password, userId, token);
          if(access){
            // Navigate to the next screen or show PIN
            setInterviewer(interviewer);
            setIsAuthenticated(true);
            navigate(navigationRoutes.dashboard);
          }
        } catch {
          setProgressBarMessage(strings.login.invalidOrExpiredToken);
          setProgressBarSeverity("error");
        }
      }else{
        const response = await authenticateUserWeb(username.trim().toLowerCase(), password);
        if (response.user_id) {
          setUserId(response.user_id);

          setProgressBarMessage(strings.dataValidations.pleaseWait);
          setProgressBarSeverity("info");
          setProgressBarVisible(true);

        }

      }
    } catch (error) {
      //console.log(error)
      setProgressBarMessage(strings.notifications.loginFailMessage);
      setProgressBarSeverity("error");
    } finally {
      // setProgressBarVisible(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?hl=${language}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [language]);

  return (
    <Container maxWidth="sm">
      <MySnackBar message={progressBarMessage} visible={progressBarVisible} severity={progressBarSeverity} onClose={onDismissProgressBar} />
      <MyLoadingBar loading={loading} />

      <Box sx={styles.loginBox}>
        <MyCard>
          <Box sx={styles.logoBox}>
            <img src={imageLogo} width={150} alt={""} loading="lazy" />
          </Box>
          <MyCardTitle title={strings.app.login} />

          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            label={strings.login.email}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            type={showPassword ? "text" : "password"}
            label={strings.login.password}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Typography
              variant="body2"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(navigationRoutes.forgotPassword)}
            >
              {strings.login.forgotPassword}
            </Typography>
          </Grid>
          {userId && <TextField
              style={{ marginTop: 10, marginBottom: 10 }}
              label={strings.login.token}
              fullWidth
              value={token}
              onChange={(e) => setToken(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Tooltip title={strings.login.tokenSentToEmail}>
                    <IconButton onClick={() => alert(strings.login.tokenSentToEmail)}>
                      <HelpOutline />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />}

          <Box mt={3}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                console.log(newValue);
                setValue(newValue);
                if (newValue?.key) {
                  setAppLanguage(newValue?.key);
                  setLanguage(newValue?.key);
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                console.log("input value", newInputValue);

                setInputValue(newInputValue);
              }}
              options={languages}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => <TextField {...params} label={strings.app.app_language} />}
            />
          </Box>
          <ReCAPTCHA style={{marginTop: 16}}
            key={language}
            sitekey={GOOGLE_RECAPTCHA_KEY}
            hl={language}
            onChange={setIsReCaptchaSet}
          />

          <Box mt={4}>
            <Button variant="contained" fullWidth startIcon={<Login />} uppercase="true" onClick={handleLogin} disabled={loading}>
              {strings.app.login}
            </Button>
          </Box>

          <Box mt={3}>
            <Button fullWidth startIcon={<PersonAdd />} onClick={() => navigate(navigationRoutes.register)}>
              {strings.login.register}
            </Button>
          </Box>
        </MyCard>
      </Box>
    </Container>
  );
};

export default LoginScreen;
