import { theme } from '../../utils/variables'

const inputsMarginBottom = 16;
const inputsPadding = 8
const inputsBackgroundColor = ''
const cardBackgroundColor = '#fffdf8'
const errorBorderColor = '#BF331C'
// const errorBorderColor = 'red'
const errorBackgroundColor = '#FFFcFc'
const borderStyle = 'solid'

const styles = {
  title: {
    flexGrow: 1,
  },
  navButton: {
    margin: '0 10px',
  },
  submitButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8,
  },
  searchButtonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  addCaseButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: 8,
    mb: 4,
  },

  logoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "32px",
  },
  loginBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    // marginTop: "64px",
  },
  settingsBox: {
    mt: 16,
  },
  cardIconButton: {
    p: 1, // Smaller padding for the button
    '& .MuiSvgIcon-root': {
      fontSize: 20, // Smaller icon size
    },
  },
  dashboardCard: {
    maxWidth: 345,
    bgcolor: theme => theme.palette.primary.main,
    color: theme => theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
  },
  exploitationBorders: {
    border: '1px solid #ccc',
    borderColor: theme => theme.palette.primary.main,
    borderRadius: '8px',
    marginBottom: 12,
  },

  container: {
    flex: 1,
    padding: 8,
  },
  card: {
    padding: 16,
    margin: 8,
    marginBottom: 32,
    backgroundColor: cardBackgroundColor,
  },
  findHelpListItem: {
    marginTop: '16px',
    marginBottom: '16px',
    cursor: 'pointer',
  },
  MyCardTitle: {
    marginBottom: 16,
    textAlign: 'center',
    color: theme.colors.primary,
  },
  MyProgressDialog: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    height: 64,
  },
  MyTextInput: {
    marginBottom: inputsMarginBottom,
    backgroundColor: inputsBackgroundColor,
    borderRadius: 8,
    padding: inputsPadding,
  },
  MyTextInputFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  MyTextInputFlexInner: {
    flex: 1,
    padding: 1,
  },
  MyInnerTextInput: {
    backgroundColor: inputsBackgroundColor,
    // padding: '8px',
  },
  MyDatePicker: {
    marginBottom: inputsMarginBottom,
    backgroundColor: inputsBackgroundColor,
  },
  MyTextLabel: {
    marginBottom: 4,
  },
  MyTextLabelError: {
    color: "red",
    marginBottom: 4,
  },
  mySelectList: {
    marginBottom: inputsMarginBottom,
    backgroundColor: inputsBackgroundColor,
    borderRadius: 8,
    padding: inputsPadding,
  },
  myRadioGroup: {
    marginBottom: inputsMarginBottom,
    backgroundColor: inputsBackgroundColor,
    borderRadius: 8,
    padding: inputsPadding,
  },
  myRadioGroupErrors: {
    borderWidth: 1,
    borderColor: errorBorderColor,
    backgroundColor: errorBackgroundColor,
    borderStyle: borderStyle,
  },
  myTextInputErrors: {
    borderWidth: 1,
    borderColor: errorBorderColor,
    backgroundColor: errorBackgroundColor,
    borderStyle: borderStyle,
  },
  mySelectListErrors: {
    borderWidth: 1,
    borderColor: errorBorderColor,
    backgroundColor: errorBackgroundColor,
    borderStyle: borderStyle,
  },
};

export default styles;
