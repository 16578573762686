import React, { useContext } from "react";
import { Card, CardContent, CardActions, Typography, Divider, IconButton, CardActionArea, CardHeader, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { People, Person, RemoveRedEye } from '@mui/icons-material';
import styles from './MyInputs/styles';
import { AuthContext } from "../App";
import MyFormsCount from "./MyFormsCount";

const MyMultipleVictimCard = ({ victim, viewPressed, modifyPressed }) => {
  const { strings, interviewer } = useContext(AuthContext);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} aria-label="">
            <People />
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={`${victim.victim_identifier}`}
        subheader={victim.interview_date}
      />
      <CardContent>
        {interviewer?.data_entry_purpose?.id == 1 ? (
          <>
            <MyFormsCount label={strings.victimCard.exploitationForm} count={victim.exploitations} />
            <MyFormsCount label={strings.victimCard.transitForm} count={victim.transists} />
          </>
        ) : interviewer?.data_entry_purpose?.id == 2 ? (
          <>
            <Typography>{`${strings.victimCard.suspectForm}: ${victim.traffickers_count}`}</Typography>
            <Typography>{`${strings.victimCard.investigationForm}: ${victim.investigations_count}`}</Typography>
          </>
        ) : interviewer?.data_entry_purpose?.id == 3 ? (
          <>
            <Typography>{`${strings.victimCard.suspectForm}: ${victim.traffickers_count}`}</Typography>
            <Typography>{`${strings.victimCard.prosecutionForm}: ${victim.prosecutions_count}`}</Typography>
          </>
        ) : interviewer?.data_entry_purpose?.id == 4 ? (
          <>
            <MyFormsCount label={strings.victimCard.assistanceForm} count={victim.assistances} />
            <MyFormsCount label={strings.victimCard.socioForm} count={victim.socios} />
          </>
        ) : null}

      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
        <IconButton
          aria-label="view"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            viewPressed(victim, null, false);
          }}
          sx={styles.cardIconButton}
        >
          <RemoveRedEye />
        </IconButton>
        <IconButton
          aria-label="edit"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            modifyPressed(victim, null, false);
          }}
          sx={styles.cardIconButton}
        >
          <EditIcon />
        </IconButton>




      </CardActions>
    </Card>

  )
};

export default MyMultipleVictimCard;
