import React, { useRef, useEffect } from "react";
import styles from './styles'
import { Typography } from "@mui/material";

const MyCardTitle = ({ variant = "h5", title="",  ...otherProps }) => {
 
  return (
    <Typography variant={variant} style={styles.MyCardTitle} {...otherProps}>
      {title}
    </Typography>
  )
};

export default MyCardTitle;
