import React, { useContext, useEffect, useState } from "react";
import { getAssistanceVictims, getSomSingleVictims, getTipCases, getVictims } from "../api/api";
// import { storeData, retrieveData } from "../utils/offlineStorage";
import { AuthContext } from "../App";
import { navigationRoutes, theme } from "../utils/variables";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import styles from "../components/MyInputs/styles";
import { useNavigate } from "react-router-dom";
import MyVictimCard from "../components/MyVictimCard";
import MyLoadingBar from "../components/MyLoadingBar";
import MyCaseCard from "../components/MyCaseCard";
import imageLogo from "../assets/icon.png";

const TipCasesScreen = ({ navigation }) => {
  const { strings, interviewer } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);
  const [victims, setVictims] = useState([]);
  const [cases, setCases] = useState([]);
  const navigate = useNavigate();

  const fetchCases = async () => {
    try {
      setRefreshing(true);
      const dataFromAPI = await getTipCases();
      console.log("dataFromAPI, ", dataFromAPI);
      setCases(dataFromAPI);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getVictims();
      console.log(dataFromAPI);
      setVictims(dataFromAPI.victims);
      // await storeData("victims", dataFromAPI);
    } catch (error) {
      console.log(error);
      // const offlineData = await retrieveData("victims");
      // if (offlineData) {
      //   setVictims(offlineData);
      //   setRefreshing(false);
      // }
    } finally {
      setRefreshing(false);
    }
  };

  const fetchAssistanceVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getAssistanceVictims();
      console.log(dataFromAPI);
      setVictims(dataFromAPI.victims);
      // await storeData("victims", dataFromAPI);
    } catch (error) {
      console.log(error);
      // const offlineData = await retrieveData("victims");
      // if (offlineData) {
      //   setVictims(offlineData);
      //   setRefreshing(false);
      // }
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    interviewer?.data_entry_purpose?.id == 1
      ? fetchVictims()
      : interviewer?.data_entry_purpose?.id == 4
      ? fetchAssistanceVictims()
      : fetchCases();
  }, []);

  const loadVictimData = () => {
    fetchVictims();
  };

  const handleAddVictim = () => {
    navigate(navigationRoutes.tipCasesNew, { state: { isCreateMode: true } });
  };

  const handleViewPressed = (victim, caseData) => {
    navigate(navigationRoutes.tipCasesNew, { state: { victim: victim, caseData: caseData, isViewMode: true } });
  };
  const handleModifyPressed = (victim, caseData) => {
    navigate(navigationRoutes.tipCasesNew, { state: { victim: victim, caseData: caseData, isUpdateMode: true } });
  };

  return (
    <Container>
      <MyLoadingBar loading={refreshing} />

      <Box sx={styles.addCaseButtonBox}>
        <Button variant="contained" icon="account-arrow-right" startIcon={<AddCircle />} onClick={handleAddVictim}>
          {interviewer?.data_entry_purpose?.id == 1 || interviewer?.data_entry_purpose?.id == 4
            ? strings.addTip.addNewVictim
            : strings.addTip.addNewCase}
        </Button>
      </Box>

      <Grid container spacing={2}>
        {cases.map((caseData) => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={caseData.id}>
            <MyCaseCard caseData={caseData} viewPressed={handleViewPressed} modifyPressed={handleModifyPressed} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        {victims.map((victim) => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={victim.id}>
            <MyVictimCard victim={victim} viewPressed={handleViewPressed} modifyPressed={handleModifyPressed} />
          </Grid>
        ))}
      </Grid>

      {cases.length == 0 && victims.length == 0 && (
        <>
          <Box sx={{...styles.logoBox, height: "80vh",}}>
            <img src={imageLogo} width={150} alt={""} loading="lazy" />
            <Typography variant="p" sx={{mt: 3}}>{strings.notifications.pleaseCreateANewCase}</Typography>
          </Box>
        </>
      )}
    </Container>
  );
};

export default TipCasesScreen;
