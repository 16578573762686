import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../../MyInputs/MyTextInput";
import { AuthContext } from "../../../App";
import MyCard from "../../MyInputs/MyCard";
import MySelectList from "../../MyInputs/MySelectList";
import MyMultipleSelectList from "../../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../../MyInputs/MyCardTitle";
import { addSomSuspect, addSuspect, fetchSomSuspectDetails, getSomSuspects, getSuspects, updateSomSuspect, updateSuspect } from "../../../api/api";
import MyDatePickerInput from "../../MyInputs/MyDatePicker";
import MyFormsGridItem from "../../MyInputs/MyFormsGridItem";
import { Grid } from "@mui/material";

const MySomSuspectedSmugglerForm = ({ d }) => {
  const formName = "TipSuspectBioForm";
  const parentFormName = "VictimProfileForm";

  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        case_id: "",
        firstName: "",
        surName: "",
        dob: "",
        age: "",
        gender: "",
        race: "",
        religions: [],
        languages: [],
        citizenship: "",
        dualCitizenship: "",
        nationality: "",
        idType: "",
        idNumber: "",
        address: "",
        lastPlaceOfResidence: "",
        countryOfBirth: "",
        dateOfArrest: "",
        roleInTrafficking: [],
        countryFrom: "",
        placeFrom: "",
        countryTo: "",
        placeTo: "",
      })
    }

    d.setStrings(strings.suspect)
    d.setFormName(formName)
    d.setParentFormName(parentFormName)
    d.setIsProfileSet(true);
    d.setGetFormData(() => fetchSomSuspectDetails)
    d.setPutFormData(() => updateSomSuspect)
    d.setPostFormData(() => addSomSuspect)

    d.setIsFirstForm(false);
    d.setRequiredId("case_id");

  }, []);

  // Effect to update profile.v_id whenever victimId changes
  useEffect(() => {
    // console.log("transist form victimId updating to ", d.victimId);
    d.setProfile((prevProfile) => ({
      ...prevProfile,
      case_id: d.caseId,
    }));
  }, [d.caseId]);


  if (!d.isProfileSet) return <div></div>

  return (
    <div>

      <MyCard>
        <MyCardTitle title={strings.victim.bioData} />
        <Grid container>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "firstName", ...d }} />
            <MyDatePickerInput d={{ name: "dob", ...d }} />
            <MySelectList d={{ name: "race", ...d, dbLookupName: "races" }} />
            <MyMultipleSelectList d={{ name: "languages", ...d, dbLookupName: "languages" }} />

          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "surName", ...d }} />
            <MySelectList d={{ name: "gender", ...d, dbLookupName: "genders" }} />
            <MyMultipleSelectList d={{ name: "religions", ...d, dbLookupName: "religions" }} />
          </MyFormsGridItem>

        </Grid>
      </MyCard>

      <MyCard>
        <MyCardTitle title={strings.victim.identifierData} />
        <Grid container>
          <MyFormsGridItem>
            <MySelectList d={{ name: "citizenship", ...d, dbLookupName: "countrys" }} />
            <MySelectList d={{ name: "idType", ...d, dbLookupName: "idTypes" }} />
            <MyTextInput d={{ name: "address", ...d }} />
            <MySelectList d={{ name: "countryOfBirth", ...d, dbLookupName: "countrys" }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MySelectList d={{ name: "dualCitizenship", ...d, dbLookupName: "countrys" }} />
            <MyTextInput d={{ name: "idNumber", ...d }} />
            <MySelectList d={{ name: "lastPlaceOfResidence", ...d, dbLookupName: "countrys" }} />
            <MyTextInput d={{ name: "placeOfBirth", ...d }} />
          </MyFormsGridItem>

        </Grid>
      </MyCard>

      <MyCard>
        {/* <MyCardTitle title={strings.victim.interviewData} /> */}
        <Grid container>
          <MyFormsGridItem>
            <MyDatePickerInput d={{ name: "dateOfArrest", ...d, strings: strings.caseData }} />
            <MySelectList d={{ name: "countryFrom", ...d, strings: strings.caseData, dbLookupName: "countrys" }} />
            <MySelectList d={{ name: "countryTo", ...d, strings: strings.caseData, dbLookupName: "countrys" }} />

          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyMultipleSelectList
              d={{ name: "roleInTrafficking", ...d, strings: strings.caseData, dbLookupName: "roleInTraffickings" }}
            />
            <MyTextInput d={{ name: "placeFrom", ...d, strings: strings.caseData }} />
            <MyTextInput d={{ name: "placeTo", ...d, strings: strings.caseData }} />

          </MyFormsGridItem>
        </Grid>
      </MyCard>

    </div>
  );
};

export default MySomSuspectedSmugglerForm;
