import React, { useContext, useEffect, useState, useRef } from "react";
// import {
//   clearOfflineModeVariables,
//   getOfflineModeVariables,
//   saveOfflineData,
//   setOfflineModeVariables,
// } from "../../utils/offlineStorage";
import { AuthContext } from "../../App";
import { globalUpdateFormData, handleDataValidation } from "../../utils/inputFieldValidations";
import MySnackBar from "../MyInputs/MySnackBar";
import { getJsonFromProfile, getProfileFromJson } from "../../utils/myFunctions";
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import styles from "../MyInputs/styles";
import MyCard from "../MyInputs/MyCard";
import MyLoadingBar from "../MyLoadingBar";
import MyTabPanel from "../MyInputs/MyTabPanel";
import MyGeneralForm from "./MyGeneralForm";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PersonAdd, TaskAlt } from "@mui/icons-material";

const MyMultipleGeneralForms = ({ onNext, FormFields, d }) => {
  const { strings, dbLookups, globalAppVariables, interviewer } = useContext(AuthContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const [formsUploaded, setFormsUploaded] = useState({});
  const [formsData, setFormsData] = useState();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTabClose = (index) => {
    const newTabTitles = tabTitles.filter((item, i) => i !== index);
    setTabTitles(newTabTitles);
    setSelectedTab(0);
  }

  const goNextTab = (index) => {
    // setSelectedTab(index + 1);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const [tabTitles, setTabTitles] = useState([{ title: "Suspect" }]);

  useEffect(() => {
    if (d.isUpdateMode || d.isViewMode) {
      setTabTitles(d.formIds);
    }
  }, [d.isUpdateMode || d.isViewMode]);




  const data = {
    ...d,
    formsUploaded: formsUploaded,
    setFormsUploaded: setFormsUploaded,

    getFormData: formsData,
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box>
        <Box position='sticky'
          top={49}
          style={{ borderBottom: '1px solid #ccc', zIndex: '10' }}
          bgcolor="background.paper"
        >
          <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
            // sx={{ width: '100%', maxWidth: '100%' }}

            >
              {
                tabTitles.map((item, index) => (
                  // <Tab key={index} label={`${item.title} #${index + 1} ${formsUploaded?.[index] ? '✅' : ''}`} />
                  <Tab key={index}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* {`${item.title} ${index + 1}`} */}
                        {`${strings.addTip.suspect} ${index + 1}`}
                        {formsUploaded?.[index] && (
                          <TaskAlt sx={{ ml: 1, color: 'green' }} />
                        )}
                        {/* {selectedTab === index &&
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent tab change on close button click
                              handleTabClose(index);
                            }}
                            sx={{ ml: 1 }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        } */}
                      </Box>
                    }
                  />


                ))
              }
              {!(d.isViewMode) &&
                <Tab
                  onClick={() => {
                    setTabTitles([...tabTitles, { title: "Suspect" }]);
                    // setSelectedTab(tabTitles.length);
                  }}
                  label={
                    <Box>
                      <PersonAdd />
                    </Box>
                  }

                />
              }
            </Tabs>
          </Box>
        </Box>

        {tabTitles.map((item, index) => (
          <MyTabPanel key={index} value={selectedTab} index={index}>
            <MyGeneralForm onNext={() => goNextTab(index)} FormFields={FormFields} d={{ ...data, formIndex: index, formId: d?.formIds?.[index] }} />
          </MyTabPanel>
        ))}

      </Box>
    </Container >

  );
};

export default MyMultipleGeneralForms;
