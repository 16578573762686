import React, { useContext, useEffect, useState } from "react";

import { Tabs, Tab, Box, Button, Container, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import styles from "../components/MyInputs/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../App";
import MyGeneralForm from "../components/MyForms/MyGeneralForm";
import MyVictimProfileForm from "../components/MyForms/MyVictimProfileForm";
import MyTabPanel from "../components/MyInputs/MyTabPanel";
import MyExploitationForm from "../components/MyForms/MyExploitationForm";
import MyTransistRouteForm from "../components/MyForms/MyTransistRouteForm";
import { theme, navigationRoutes } from "../utils/variables";
import MyConsentForm from "../components/MyConsentForm";
import MyVictimSearchForm from "../components/searchForms/MyVictimSearchForm";
import MySomVictimSearchForm from "../components/searchForms/MySomVictimSearchForm";




const SearchScreen = ({ d }) => {
  const { strings } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [formsUploaded, setFormsUploaded] = useState({});
  const [consentGiven, setConsentGiven] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { isUpdateMode, isViewMode, isCreateMode, victimId, caseId } = location.state || {};

  const [tabsVictimId, setTabsVictimId] = useState(victimId);
  const [tabsCaseId, setTabsCaseId] = useState(caseId);

  // console.log(location);
  // console.log("isView ",isUpdateMode," isUpdate ", isViewMode);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const goBackHome = () => {
    navigate(navigationRoutes.tipCases);

  };

  const goNextTab = (index) => {
    setSelectedTab(index);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const data = {
    victimId: tabsVictimId,
    caseId: tabsCaseId,
    setVictimId: setTabsVictimId,
    setCaseId: setTabsCaseId,
    isUpdateMode: isUpdateMode,
    isViewMode: isViewMode,
    formsUploaded: formsUploaded,
    setFormsUploaded: setFormsUploaded,
  };



  return (
    <Container>
      {isCreateMode && <MyConsentForm open={!consentGiven} handleOk={() => setConsentGiven(true)} handleCancel={() => goBackHome()} />}
      <Box>
        <Box position='sticky'
          top={0}
          style={{ borderBottom: '1px solid #ccc', zIndex: '10' }}
          bgcolor="background.paper"
        >
          <Tabs value={selectedTab} onChange={handleChange} centered>
            <Tab label={strings.appMenu.tip} />
            <Tab label={strings.appMenu.som} />

          </Tabs>
        </Box>

        <MyTabPanel value={selectedTab} index={0}><MyVictimSearchForm /></MyTabPanel>
        <MyTabPanel value={selectedTab} index={1}><MySomVictimSearchForm /></MyTabPanel>

      </Box>
    </Container>


  );
};

export default SearchScreen;
