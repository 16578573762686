import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MySelectList from "../MyInputs/MySelectList";
import MyMultipleSelectList from "../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addProsecution, getProsecutions, updateProsecution } from "../../api/api";
import MyDatePickerInput from "../MyInputs/MyDatePicker";
import MyRadioGroup from "../MyInputs/MyRadioGroup";
import { Grid } from "@mui/material";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";

const MyProsecutionForm = ({ d }) => {
  const formName = "ProsecutionForm";
  const parentFormName = "VictimProfileForm";

  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        case_id: "",
        suspectedTrafficker: "",
        caseStatus: "",
        trialCourt: "",
        foreignCourtCountry: "",
        courtCaseNumber: "",
        verdict: "",
        guiltyVerdict: "",
        guiltyVerdictReason: "",
        prosecutionOutcome: "",
        acquittalReason: "",
        reviewAppealOutcome: "",
        penalty: "",
        yearsImposed: "",
      })
    }

    d.setStrings(strings.prosecution)
    d.setFormName(formName)
    d.setParentFormName(parentFormName)
    d.setIsProfileSet(true);
    d.setGetFormData(() => getProsecutions)
    d.setPutFormData(() => updateProsecution)
    d.setPostFormData(() => addProsecution)

    d.setIsFirstForm(false);
    d.setRequiredId("case_id");

  }, []);

  // Effect to update profile.v_id whenever victimId changes
  useEffect(() => {
    // console.log("transist form victimId updating to ", d.victimId);
    d.setProfile((prevProfile) => ({
      ...prevProfile,
      case_id: d.caseId,
    }));
  }, [d.caseId]);


  if (!d.isProfileSet) return <div></div>

  return (
    <div>
      <MyCard>
        <Grid container>
          <MyFormsGridItem>
            <MySelectList d={{ name: "caseStatus", ...d, dbLookupName: "caseStatuses" }} />
            <MySelectList d={{ name: "trialCourt", ...d, dbLookupName: "trialCourts" }} />
            <MySelectList d={{ name: "foreignCourtCountry", ...d, dbLookupName: "countrys" }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "courtCaseNumber", ...d }} />
            <MySelectList d={{ name: "verdict", ...d, dbLookupName: "verdicts" }} />
            <MySelectList d={{ name: "guiltyVerdictReason", ...d, dbLookupName: "guiltyReasons", stringName: "guiltyVerdict" }} />
          </MyFormsGridItem>
        </Grid>
      </MyCard>


      <MyCard>
        {/* <Text style={{ marginTop: 10, marginBottom: 10 }}>{strings.prosecution.suspectedTraficker}</Text>
        <SelectList setSelected={(val) => updateFormData("suspectedTrafficker", val)} data={suspects} save="id" /> */}

        <Grid container>
          <MyFormsGridItem>
            <MySelectList d={{ name: "prosecutionOutcome", ...d, dbLookupName: "prosecutionOutcomes" }} />
            <MySelectList d={{ name: "acquittalReason", ...d, dbLookupName: "aquitalReasons" }} />
            <MyTextInput d={{ name: "reviewAppealOutcome", ...d }} multiline rows={4} />
          </MyFormsGridItem>
          <MyFormsGridItem>

            <MySelectList d={{ name: "penalty", ...d, dbLookupName: "sanctionPenaltys" }} />
            <MyTextInput d={{ name: "yearsImposed", ...d }} inputMode="number" inputProps={{ min: 0 }} />
          </MyFormsGridItem>
        </Grid>


      </MyCard>
    </div>
  );
};

export default MyProsecutionForm;
