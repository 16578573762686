import React, { useContext } from "react";
import { Card, CardContent, CardActions, Typography, Divider, IconButton, CardActionArea, CardHeader, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Person, RemoveRedEye } from '@mui/icons-material';
import styles from './MyInputs/styles';
import { AuthContext } from "../App";

const MyVictimSearchCard = ({ victim, viewPressed, modifyPressed }) => {
  const { strings, interviewer } = useContext(AuthContext);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} aria-label="">
            <Person />
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={`${victim.victim_identifier}`}
        subheader={victim.interview_date}
      />
      <CardContent>
        {victim.initials}

      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
        <IconButton
          aria-label="view"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            viewPressed(victim, null, true);
          }}
          sx={styles.cardIconButton}
        >
          <RemoveRedEye />
        </IconButton>
        <IconButton
          aria-label="edit"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            modifyPressed(victim, null, true);
          }}
          sx={styles.cardIconButton}
        >
          <EditIcon />
        </IconButton>




      </CardActions>
    </Card>

  )
};

export default MyVictimSearchCard;
