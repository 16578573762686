import { createTheme } from '@mui/material';
import { theme } from './variables';

const materialTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
      60: theme.colors.primary60,
    },
    secondary: {
      main: theme.colors.secondary,
    },
  },
});


export default materialTheme;
