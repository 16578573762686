import React, { useContext } from "react";
import { Card, CardContent, CardActions, Typography, Divider, IconButton, CardActionArea, CardHeader, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Person, RemoveRedEye } from '@mui/icons-material';
import styles from './MyInputs/styles';
import { AuthContext } from "../App";
import MyFormsCount from "./MyFormsCount";

const MyCaseCard = ({ caseData, viewPressed, modifyPressed }) => {
  const { strings, interviewer } = useContext(AuthContext);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} aria-label="">
            <Person />
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        // title={`${caseData.traffick_from_place ?? ""}     ${caseData.traffick_to_place ?? ""}`}
        title={`${caseData.case_number ?? ""}`}
        subheader={caseData.date_of_arrest}
      />
      <CardContent>
        {/* <Typography>{`${strings.caseData.countryFrom}: ${caseData.traffick_from_country?.name}`}</Typography>
            <Typography>{`${strings.caseData.countryTo}: ${caseData.traffick_to_country?.name}`}</Typography> */}
        {interviewer?.data_entry_purpose?.id == 1 ?
          <>
            <Typography>{`${strings.victimCard.exploitationForm}: ${caseData.exploitation_count}`}</Typography>
            <Typography>{`${strings.victimCard.transitForm}: ${caseData.destinations_count}`}</Typography>
          </>
          : interviewer?.data_entry_purpose?.id == 2 ?
            <>
              <MyFormsCount label={strings.victimCard.investigationForm} count={caseData.investigations} />
              <MyFormsCount label={strings.victimCard.suspectForm} count={caseData.suspects} />
            </>
            : interviewer?.data_entry_purpose?.id == 3 ?
              <>
                <MyFormsCount label={strings.victimCard.prosecutionForm} count={caseData.prosecutions} />
                <MyFormsCount label={strings.victimCard.suspectForm} count={caseData.suspects} />
              </>
              : interviewer?.data_entry_purpose?.id == 4 ?
                <>
                  <Typography>{`${strings.victimCard.assistanceForm}: ${caseData.assistance_count}`}</Typography>
                  <Typography>{`${strings.victimCard.socioForm}: ${caseData.socio_economic_count}`}</Typography>
                </>
                : null

        }

      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
        <IconButton
          aria-label="view"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            viewPressed(null, caseData);
          }}
          sx={styles.cardIconButton}
        >
          <RemoveRedEye />
        </IconButton>
        <IconButton
          aria-label="edit"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            modifyPressed(null, caseData);
          }}
          sx={styles.cardIconButton}
        >
          <EditIcon />
        </IconButton>




      </CardActions>
    </Card>

  )
};

export default MyCaseCard;
