import React, { useContext } from 'react';
import { AuthContext } from '../../App';
import MyRadioGroup from './MyRadioGroup';

const MyYesNoRadioGroup = ({ d, ...otherProps }) => {
  const { strings } = useContext(AuthContext);
  const options = [
    { key: "true", value: strings.yesNo.yes },
    { key: "false", value: strings.yesNo.no },
  ]

  const localUpdateFormData = (name, value) => {
    let boolValue = value === "true" ? true : value === "false" ? false : null;
    d.updateFormData(name, boolValue);
  }


  return (
    <MyRadioGroup
      d={{ ...d, updateFormData: localUpdateFormData, overrideValues: options }}
    />
  );
};



export default MyYesNoRadioGroup;
