import React, { useContext, useState } from 'react';
import { Modal, Box, Typography, FormControlLabel, Checkbox, Button, Divider, Fade, List, ListItem, ListItemText } from '@mui/material';
import { AuthContext } from '../App';
import Slide from '@mui/material/Slide';

const MyConsentForm = ({ open, handleOk, handleCancel, isSomConsent }) => {
  const { strings } = useContext(AuthContext);
  const [minorOrImpairment, setMinorOrImpairment] = useState(false);

  const [checkedItems, setCheckedItems] = useState({
    shareData: false,
    limitedDisclosure: false,
    anonymousDataUse: false,
    rightToRefuse: false,
  });

  const allConsentsGiven = Object.values(checkedItems).every((value) => value === true);

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => reason !== 'backdropClick' && handleCancel()}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Center modal
    >

      <Slide direction="up" in={open} >
        <Box
          sx={innerStyles.box}
        >
          <Typography id="custom-modal-title" variant="h6" component="h2">
            {strings.tipMenu.consent}
          </Typography>

          <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
            <FormControlLabel
              sx={innerStyles.checkBoxStyles}
              control={<Checkbox checked={checkedItems.shareData} onChange={handleCheckboxChange} name="shareData" />}
              label={strings.consent.shareData}
            />
            <Divider />
            <FormControlLabel
              sx={innerStyles.checkBoxStyles}
              control={<Checkbox checked={checkedItems.limitedDisclosure} onChange={handleCheckboxChange} name="limitedDisclosure" />}
              label={strings.consent.limitedDisclosure}
            />
            <Divider />
            <FormControlLabel
              sx={innerStyles.checkBoxStyles}
              control={<Checkbox checked={checkedItems.anonymousDataUse} onChange={handleCheckboxChange} name="anonymousDataUse" />}
              label={strings.consent.anonymousDataUse}
            />
            <Divider />
            <FormControlLabel
              sx={innerStyles.checkBoxStyles}
              control={<Checkbox checked={checkedItems.rightToRefuse} onChange={handleCheckboxChange} name="rightToRefuse" />}
              label={strings.consent.rightToRefuse}
            />
            <Divider />
            <FormControlLabel
              sx={innerStyles.checkBoxStyles}
              control={<Checkbox checked={minorOrImpairment} onChange={() => setMinorOrImpairment(!minorOrImpairment)} name="minorOrImpairment" />}
              label={strings.consent.minorOrImpairment}
            />
            {isSomConsent && (
              <>
                <Divider />
                <Box sx={{color: 'red'}}>
                  <Typography variant="body2">{strings.consent.dataCollectorNoteTitle}</Typography>
                  <List component="ol">
                    <ListItem>
                      <ListItemText primary={strings.consent.dataCollectorNote1} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={strings.consent.dataCollectorNote2} />
                    </ListItem>
                  </List>

                </Box>
              </>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleCancel} color="primary" sx={{ marginRight: 2 }}>
              {strings.app.cancel}
            </Button>
            <Button onClick={handleOk} variant='contained' color="primary" disabled={!allConsentsGiven}>
              {strings.app.submit}
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

const innerStyles = {
  checkBoxStyles: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)', // light gray background on hover
    },
    py: 2,
  },
  box: {
    position: 'relative',
    width: '80%',
    // maxWidth: 400,
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    p: 4,
  }
};

export default MyConsentForm;
