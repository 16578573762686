import React, { useState, useEffect, useRef } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from "@mui/material";
import styles from './styles'
import { scrollToComponent } from "../../utils/inputFieldValidations";

const MyRadioGroup = ({ d, ...otherProps }) => {
  if (!d) return <></>;

  const [selectedGender, setSelectedGender] = useState("");
  var dbLookupName = d.dbLookupName || d.name;
  var stringName = d.stringName || d.name;
  const componentRef = useRef(null);

  var values;
  if (d.overrideValues) values = d.overrideValues;
  else values = d.dbLookups[dbLookupName];

  const selectGender = (value) => {
    setSelectedGender(value);
    d.updateFormData(d.name, value);
  };

  useEffect(() => {
    setSelectedGender(d.profile[d.name]);
  }, [d.profile[d.name]]);

  scrollToComponent(d.formValidation?.[d.name], d.scrollViewRef, componentRef, d.scrollViewHeight);

  return (
    <div ref={componentRef} style={{ ...styles.myRadioGroup, ...(d.formValidation?.[d.name]?.["isMandatory"] ? styles.myRadioGroupErrors : {}) }}>
      <Typography>
        {d.formValidation?.[d.name]?.["isMandatory"] ? "* " : ""}
        {d.strings[stringName]}
      </Typography>
      <RadioGroup onChange={(e) => selectGender(e.target.value)} value={selectedGender}>
        <div style={innerStyles.radioGroup}>
          {values.map((option) => (
            <FormControlLabel key={option.key} value={option.key} control={<Radio />} label={option.value} />
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

const innerStyles = {
  radioGroup: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  radioItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
    // marginBottom: 10,
  },
};

export default MyRadioGroup;
