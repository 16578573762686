import React, { useEffect, useContext, useState, useRef } from "react";
import { AuthContext } from "../../../App";
import MyCard from "../../MyInputs/MyCard";
import MyCardTitle from "../../MyInputs/MyCardTitle";
import { Box, Button, Typography } from "@mui/material";
import { People, Person } from "@mui/icons-material";

const MySomVictimSelector = ({ setIsSingleVictim }) => {
  const { strings } = useContext(AuthContext);

  return (
    <div>
      <Box height={128} />
      <MyCard>
        <Typography variant="body1" sx={{textAlign: 'center', m:4}}>{strings.victim.victimSelectorTitle}</Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            startIcon={<Person sx={{color: theme=> theme.palette.secondary.main}}/>}
            onClick={() => setIsSingleVictim(true)}
          >
            {strings.decisionPopup.singleVictim}
          </Button>
          <Box width={16} />

          <Button
            variant="contained"
            startIcon={<People sx={{color: theme=> theme.palette.secondary.main}}/>}
            onClick={() => setIsSingleVictim(false)}
          >
            {strings.decisionPopup.multipleVictim}
          </Button>
        </Box>

      </MyCard>

    </div>
  );
};

export default MySomVictimSelector;
