import React, { useContext, useState, useRef, useEffect } from "react";
import { AuthContext } from "../../App";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styles from "./styles";
import MyTextInput from "./MyTextInput";
import { scrollToComponent } from "../../utils/inputFieldValidations";
import { Checkbox, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyMultipleSelectList = ({ d, ...otherProps }) => {
  var dbLookupName = d.dbLookupName || d.name;
  var stringName = d.stringName || d.name;
  const [showOtherField, setShowOtherField] = useState(false);
  const [defaultOption, setDefaultOption] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const componentRef = useRef(null);

  const { strings } = useContext(AuthContext);

  const handleChange = (val) => {
    let otherFieldSelected = val?.some((item) => item?.value?.toLowerCase().startsWith(strings?.dataValidations?.otherField?.toLowerCase()));

    if (otherFieldSelected) {
      setShowOtherField(true);
    } else {
      setShowOtherField(false);
    }
    //check if one of the values starts with other


    // get list of keys
    var keys = val?.map((item) => item.key);
    // console.log("keys", keys);
    d.updateFormData(d.name, keys || []);
  };


  useEffect(() => {
    // get items from dbLookups that match the keys in the profile
    var defaultOptions = d.profile[d.name]?.map((item) => d.dbLookups[dbLookupName].find((dbItem) => dbItem.key == item));
    if (defaultOptions) setValue(defaultOptions);

  }, [d.profile[d.name]]);


  var values;
  if (d.overrideValues) values = d.overrideValues;
  else values = d.dbLookups[dbLookupName];

  var label = d.strings?.[stringName];
  label = d.formValidation?.[d.name]?.["isMandatory"] ? "* " + label : label;

  var longLabel = label?.length > 50;



  scrollToComponent(d.formValidation?.[d.name], d.scrollViewRef, componentRef, d.scrollViewHeight);

  return (
    <div
      ref={componentRef}
      style={{
        ...(d.noStyling ? null : styles.mySelectList),
        ...(d.formValidation?.[d.name]?.["isMandatory"] ? styles.mySelectListErrors : {}),
      }}
    >

      {longLabel ? (
        <Typography style={styles.MyTextLabel}>
          {label}
        </Typography>
      ) : (
        <></>
      )}

      <Autocomplete
        value={value}
        multiple
        disableCloseOnSelect
        onChange={(event, newValue) => {
          handleChange(newValue)
        }}
        options={values}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => <TextField {...params} label={longLabel ? null : label} />}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.value}
            </li>
          );
        }}
      />

      {showOtherField ? (
        <div style={{ marginTop: 32 }}>
          <MyTextInput
            d={{ ...d, name: d.name + "Other", noStyling: true }}
            overrideLabel={strings?.dataValidations?.enterOtherField + " " + d.strings[stringName]}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyMultipleSelectList;
