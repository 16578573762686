import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../App";
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styles from "./styles";
import { scrollToComponent } from "../../utils/inputFieldValidations";

const MyDatePickerInput = ({ d, mode = "outlined" }) => {
  const [inputDate, setInputDate] = useState(null);
  const [isDefaultDateSet, setIsDefaultDateSet] = useState(false);
  var stringName = d.stringName || d.name;
  const { strings } = useContext(AuthContext);

  const handleChange = (value) => {
    setInputDate(value);
    // Format the date to yyyy-mm-dd
    const formattedDate = value?.toISOString().split("T")[0];
    d.updateFormData(d.name, formattedDate);
  };

  const errorsExist = d.formValidation?.[d.name]?.["isMandatory"] || d.formValidation?.[d.name]?.["errors"];
  const componentRef = useRef(null);

  useEffect(() => {
    // Parse the initial date string from d.profile[d.name]
    const initialDateString = d.profile[d.name];
    const profileDate = initialDateString ? new Date(initialDateString) : undefined;

    if (profileDate !== inputDate && profileDate) {
      setInputDate(dayjs(profileDate));
    }
  }, [d.profile[d.name]]);

  useEffect(() => {
    const defaultDate = d.defaultValue ? d.defaultValue : undefined;
    if (isDefaultDateSet === false && defaultDate) {
      handleChange(dayjs(defaultDate));
      setIsDefaultDateSet(true);
    }
  }, [d.defaultValue]);

  scrollToComponent(d.formValidation?.[d.name], d.scrollViewRef, componentRef, d.scrollViewHeight);

  return (
    <div style={{ ...styles.MyTextInput, ...(errorsExist ? styles.myTextInputErrors : {}) }} ref={componentRef}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={d.strings[stringName]}
          value={inputDate}
          onChange={handleChange}
          maxDate={dayjs(d.endDate || new Date())}
          inputFormat="dd-MM-yyyy"
          slotProps={{ textField: { fullWidth: true } }}
        />
      </LocalizationProvider>
      {/* <DatePickerInput
        locale="en-GB"
        label={d.strings[stringName]}
        value={inputDate}
        onChange={handleChange}
        inputMode="none"
        // withDateFormatInLabel={false}
        validRange={{ endDate: d.endDate || new Date() }}
        presentationStyle="overFullScreen"
        placeholder={strings.datePicker.placeholder}
        saveLabel={strings.datePicker.confirmLabel}
        cancelLabel={strings.datePicker.cancelLabel}
        style={styles.MyDatePicker}
        mode={mode}
      /> */}
    </div>
  );
};

export default MyDatePickerInput;
