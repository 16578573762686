import React, { useContext, useEffect, useState } from "react";
import { getSomAssistanceMultiVictims, getSomAssistances, getSomAssistanceVictims, getSomCases, getSomMultiVictims, getSomSingleVictims, getVictims } from "../api/api";
import { AuthContext } from "../App";
import { navigationRoutes, theme } from "../utils/variables";
import { Box, Button, Container, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import styles from "../components/MyInputs/styles";
import { useNavigate } from "react-router-dom";
import MyVictimCard from "../components/MyVictimCard";
import MyLoadingBar from "../components/MyLoadingBar";
import MyCaseCard from "../components/MyCaseCard";
import MyMultipleVictimCard from "../components/MyMultipleVictimCard";

const SomCasesScreen = ({ navigation }) => {
  const { strings, interviewer } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);
  const [victims, setVictims] = useState([]);
  const [multipleVictims, setMultipleVictims] = useState([]);
  const [cases, setCases] = useState([]);
  const navigate = useNavigate();


  const fetchCases = async () => {
    try {
      setRefreshing(true);
      const dataFromAPI = await getSomCases();
      console.log("dataFromAPI, ", dataFromAPI );
      setCases(dataFromAPI);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getSomSingleVictims();
      console.log(dataFromAPI);
      setVictims(dataFromAPI);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };
  const fetchMultipleVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getSomMultiVictims();
      console.log(dataFromAPI);
      setMultipleVictims(dataFromAPI);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };


  const fetchAssistanceVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getSomAssistanceVictims();
      console.log(dataFromAPI);
      setVictims(dataFromAPI.victims);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };
  const fetchAssistanceMultiVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getSomAssistanceMultiVictims();
      console.log(dataFromAPI);
      setMultipleVictims(dataFromAPI.victims);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    if(interviewer?.data_entry_purpose?.id == 1){
      fetchVictims();
      fetchMultipleVictims();
    }else if(interviewer?.data_entry_purpose?.id == 4){
      fetchAssistanceVictims();
      fetchAssistanceMultiVictims();
    }else{
      fetchCases();
    }
  }, []);

  const loadVictimData = () => {
    fetchVictims();
  };

  const handleAddVictim = () => {
    navigate(navigationRoutes.somCasesNew, { state: { isCreateMode: true } });
  };

  const handleViewPressed = (victim, caseData, isSomSingleVictim) => {
    navigate(navigationRoutes.somCasesNew, { state: { victim: victim, caseData: caseData, isSomSingleVictim: isSomSingleVictim, isViewMode: true } });

  };
  const handleModifyPressed = (victim, caseData, isSomSingleVictim) => {
    navigate(navigationRoutes.somCasesNew, { state: { victim: victim, caseData: caseData, isSomSingleVictim: isSomSingleVictim, isUpdateMode: true } });

  };

  return (
    <Container>
      <MyLoadingBar loading={refreshing} />

      <Box sx={styles.addCaseButtonBox}>
        <Button
          variant="contained"
          icon="account-arrow-right"
          startIcon={<AddCircle />}
          onClick={handleAddVictim}
        >
          {interviewer?.data_entry_purpose?.id == 1 || interviewer?.data_entry_purpose?.id == 4 ? strings.addSom.addNewVictim : strings.addSom.addNewCase}
        </Button>
      </Box>

      <Grid container spacing={2}>
        {cases.map(caseData => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={caseData.id}>
            <MyCaseCard
              caseData={caseData}
              viewPressed={handleViewPressed}
              modifyPressed={handleModifyPressed}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        {victims.map(victim => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={victim.id}>
            <MyVictimCard
              victim={victim}
              viewPressed={handleViewPressed}
              modifyPressed={handleModifyPressed}
            />
          </Grid>
        ))}
        {multipleVictims.map(victim => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={victim.id}>
            <MyMultipleVictimCard
              victim={victim}
              viewPressed={handleViewPressed}
              modifyPressed={handleModifyPressed}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
      </Grid>

      
    </Container>
  );
};

export default SomCasesScreen;
