import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, List, ListItemIcon, ListItemText, Typography, Toolbar, CssBaseline, IconButton, Box, ListItemButton, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled, useTheme } from '@mui/system';
import { navigationRoutes } from '../utils/variables';
import styles from '../components/MyInputs/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AuthContext } from '../App';
import SettingsIcon from '@mui/icons-material/Settings';
import { Logout, Person, Help, Search, AccountCircle } from '@mui/icons-material';
import MyLogoutDialog from '../components/MyLogoutDialog';
import { API_URL } from '../api/api';
import MyUserProfileCard from '../components/MyUserProfileCard';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledListItem = styled(ListItemButton)(({ theme, selected }) => ({
  '& .MuiListItemIcon-root': {
    color: theme.palette.primary.contrastText,
    minWidth: 'auto',
    marginRight: 8,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  '&:hover': {
    color: theme.palette.secondary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.secondary.main,
    },
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

function Menu({ logout }) {
  const { strings } = useContext(AuthContext);
  const theme = useTheme();

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(() => {
    const savedState = localStorage.getItem('drawerOpen');
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    localStorage.setItem('drawerOpen', JSON.stringify(drawerOpen));
  }, [drawerOpen]);

  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
    logout();
  };

  const handleLogoutClose = () => {
    setLogoutDialogOpen(false);
  };

  const handleListItemClick = (event) => {
    if(isSmallScreen) {
      toggleDrawer();
    }
  }

  return (
    <>
      <CssBaseline />
      <StyledDrawer
        variant={isSmallScreen ? 'temporary' : drawerOpen ? 'persistent' : 'temporary'}
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar>
          <StyledListItem component={Link} to={API_URL}>
            <Typography variant="h4" noWrap color='secondary'>
              TipSom
            </Typography>
          </StyledListItem>
          <ChevronLeftIcon onClick={toggleDrawer} style={{ marginLeft: 'auto', color: 'white' }}>
            <MenuIcon />
          </ChevronLeftIcon>
        </Toolbar>
        <List>
          <MyUserProfileCard />
          <StyledListItem component={Link} to={navigationRoutes.dashboard} onClick={handleListItemClick} selected={location.pathname === navigationRoutes.dashboard}>
            <ListItemText primary={strings.appMenu.dashboard} />
          </StyledListItem>
          <StyledListItem component={Link} to={navigationRoutes.tipCases} onClick={handleListItemClick} selected={location.pathname.startsWith(navigationRoutes.tipCases)}>
            <ListItemText primary={strings.appMenu.tip} />
          </StyledListItem>
          <StyledListItem component={Link} to={navigationRoutes.somCases} onClick={handleListItemClick} selected={location.pathname.startsWith(navigationRoutes.somCases)}>
            <ListItemText primary={strings.appMenu.som} />
          </StyledListItem>
          <Divider sx={{ mb: 4 }} />


          <StyledListItem component={Link} to={navigationRoutes.search} onClick={handleListItemClick} selected={location.pathname === navigationRoutes.search}>
            <ListItemIcon><Search /></ListItemIcon>
            <ListItemText primary={strings.appMenu.search} />
          </StyledListItem>
          <StyledListItem component={Link} to={navigationRoutes.findHelp} onClick={handleListItemClick} selected={location.pathname === navigationRoutes.findHelp}>
            <ListItemIcon><Help /></ListItemIcon>
            <ListItemText primary={strings.appMenu.findHelp} />
          </StyledListItem>

          <Divider sx={{ mb: 8 }} />
          {/* <StyledListItem component={Link} to={navigationRoutes.profile} selected={location.pathname === navigationRoutes.profile}>
            <ListItemIcon><AccountCircle /></ListItemIcon>
            <ListItemText primary={strings.appMenu.profile} />
          </StyledListItem> */}
          <StyledListItem component={Link} to={navigationRoutes.settings} onClick={handleListItemClick} selected={location.pathname === navigationRoutes.settings}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary={strings.appMenu.settings} />
          </StyledListItem>
          <StyledListItem component={Link} onClick={handleLogoutClick} selected={location.pathname === navigationRoutes.logout}>
            <ListItemIcon><Logout /></ListItemIcon>
            <ListItemText primary={strings.logout.logoutTitle} />
          </StyledListItem>

          <MyLogoutDialog
            open={logoutDialogOpen}
            onClose={handleLogoutClose}
            onConfirm={handleLogoutConfirm}
          />
        </List>
      </StyledDrawer>
      {!drawerOpen && (
        <IconButton
          onClick={toggleDrawer}
          style={{ position: 'fixed', top: 16, left: 16, zIndex: '20' }}
        >
          <MenuIcon />
        </IconButton>
      )}

    </>
  );
}

export default Menu;
