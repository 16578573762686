import { Grid } from "@mui/material";

const MyFormsGridItem = ({ children }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} mb={6} sx={{ paddingX: '8px', m: 0}}
    //  style={{borderTop: '1px solid #E4E4E4', padding: '4px'}}
    >
      {children}
    </Grid>
  )
}

export default MyFormsGridItem;