import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { AuthContext } from '../App';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyLogoutDialog = ({ open, onClose, onConfirm }) => {
  const { strings } = useContext(AuthContext);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle>{strings.logout.logoutTitle}</DialogTitle>
      <DialogContent>{strings.logout.logoutText}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">{strings.app.cancel}</Button>
        <Button onClick={onConfirm} color="primary" autoFocus>{strings.logout.logoutButton}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MyLogoutDialog;
