import React, { useContext, useEffect, useState } from "react";

import { Tabs, Tab, Box, Button, Container, Typography } from "@mui/material";
import { TaskAlt } from "@mui/icons-material";
import styles from "../components/MyInputs/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../App";
import MyGeneralForm from "../components/MyForms/MyGeneralForm";
import MyVictimProfileForm from "../components/MyForms/MyVictimProfileForm";
import MyTabPanel from "../components/MyInputs/MyTabPanel";
import MyExploitationForm from "../components/MyForms/MyExploitationForm";
import MyTransistRouteForm from "../components/MyForms/MyTransistRouteForm";
import { theme, navigationRoutes } from "../utils/variables";
import MyConsentForm from "../components/MyConsentForm";
import MySuspectedTraffickerForm from "../components/MyForms/MySuspectedTraffickerForm";
import MyInvestigationsForm from "../components/MyForms/MyInvestigationsForm";
import MyProsecutionForm from "../components/MyForms/MyProsecutionForm";
import MySnackBar from "../components/MyInputs/MySnackBar";
import MyCaseForm from "../components/MyForms/MyCaseForm";
import MyMultipleGeneralForms from "../components/MyForms/MyMultipleGeneralForms";
import MyAssistanceTypesForm from "../components/MyForms/MyAssistanceTypesForm";
import MySocioEconomicForm from "../components/MyForms/MySocioEconomicForm";
import MyAssistanceVictimProfileForm from "../components/MyForms/MyAssistanceVictimProfileForm";

const TipCasesAddScreen = ({ d }) => {
  const { strings, interviewer } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [formsUploaded, setFormsUploaded] = useState({});
  const [consentGiven, setConsentGiven] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { isUpdateMode, isViewMode, isCreateMode, victim, caseData } = location.state || {};

  const [tabsVictimId, setTabsVictimId] = useState(victim?.id);
  const [tabsCaseId, setTabsCaseId] = useState(caseData?.id);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");
  const onDismissProgressBar = () => {
    setProgressBarVisible(false);
  };

  // console.log(location);
  // console.log("isView ",isUpdateMode," isUpdate ", isViewMode);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const goBackHome = () => {
    navigate(navigationRoutes.tipCases);
  };

  const goNextTab = (index) => {
    setSelectedTab(index + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const showSnackBar = (message, severity) => {
    setProgressBarMessage(message);
    setProgressBarSeverity(severity);
    setProgressBarVisible(true);
  };

  const data = {
    victimId: tabsVictimId,
    caseId: tabsCaseId,
    victim: victim,
    caseData: caseData,
    setVictimId: setTabsVictimId,
    setCaseId: setTabsCaseId,
    isUpdateMode: isUpdateMode,
    isViewMode: isViewMode,
    formsUploaded: formsUploaded,
    setFormsUploaded: setFormsUploaded,
    showSnackBar: showSnackBar,
  };

  const [tabTitles] = useState(
    interviewer?.data_entry_purpose?.id == 1
      ? [
          { key: "victimProfile", title: strings.addTip.victimProfile },
          { key: "exploitation", title: strings.addTip.exploitation },
          { key: "transitRoute", title: strings.addTip.transitRoute },
        ]
      : interviewer?.data_entry_purpose?.id == 2
      ? [
          { key: "case", title: strings.addTip.case },
          { key: "investigations", title: strings.addTip.investigations },
          { key: "suspects", title: strings.addTip.suspects },
        ]
      : interviewer?.data_entry_purpose?.id == 3
      ? [
          { key: "case", title: strings.addTip.case },
          { key: "prosecutions", title: strings.addTip.prosecutions },
          { key: "suspects", title: strings.addTip.suspects },
        ]
      : [
          { key: "victimProfile", title: strings.addTip.victimProfile },
          { key: "assistance", title: strings.addTip.assistance },
          { key: "socio", title: strings.addTip.socio },
        ]
  );

  return (
    <Container>
      {isCreateMode && interviewer?.data_entry_purpose?.id == 1 && (
        <MyConsentForm open={!consentGiven} handleOk={() => setConsentGiven(true)} handleCancel={() => goBackHome()} />
      )}
      <MySnackBar message={progressBarMessage} severity={progressBarSeverity} visible={progressBarVisible} onClose={onDismissProgressBar} />

      <Box>
        <Box position="sticky" top={0} style={{ borderBottom: "1px solid #ccc", zIndex: "10" }} bgcolor="background.paper">
          <Tabs value={selectedTab} onChange={handleChange} centered>
            {tabTitles.map((item, index) => (
              // <Tab key={index} label={`${item.title} ${formsUploaded?.[index] ? '✅' : ''}`} />
              <Tab
                key={index}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item.title}
                    {formsUploaded?.[index] && <TaskAlt sx={{ ml: 1, color: "green" }} />}
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>
        {interviewer?.data_entry_purpose?.id == 1 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyGeneralForm onNext={() => goNextTab(0)} FormFields={MyVictimProfileForm} d={{ ...data, formIndex: 0 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MyGeneralForm onNext={() => goNextTab(1)} FormFields={MyExploitationForm} d={{ ...data, formIndex: 1 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyGeneralForm onNext={() => goBackHome()} FormFields={MyTransistRouteForm} d={{ ...data, formIndex: 2 }} />
            </MyTabPanel>
          </>
        ) : interviewer?.data_entry_purpose?.id == 2 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyGeneralForm onNext={() => goNextTab(0)} FormFields={MyCaseForm} d={{ ...data, formIndex: 0 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MyGeneralForm onNext={() => goNextTab(1)} FormFields={MyInvestigationsForm} d={{ ...data, formIndex: 1 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyMultipleGeneralForms
                onNext={() => goBackHome()}
                FormFields={MySuspectedTraffickerForm}
                d={{ ...data, formIndex: 2, formIds: caseData?.suspects }}
              />
            </MyTabPanel>
          </>
        ) : interviewer?.data_entry_purpose?.id == 3 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyGeneralForm onNext={() => goNextTab(0)} FormFields={MyCaseForm} d={{ ...data, formIndex: 0 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MyGeneralForm onNext={() => goNextTab(1)} FormFields={MyProsecutionForm} d={{ ...data, formIndex: 1 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyMultipleGeneralForms
                onNext={() => goBackHome()}
                FormFields={MySuspectedTraffickerForm}
                d={{ ...data, formIndex: 2, formIds: caseData?.suspects }}
              />
            </MyTabPanel>
          </>
        ) : (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyGeneralForm onNext={() => goNextTab(0)} FormFields={MyAssistanceVictimProfileForm} d={{ ...data, formIndex: 0 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MyGeneralForm onNext={() => goNextTab(1)} FormFields={MyAssistanceTypesForm} d={{ ...data, formIndex: 1 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyGeneralForm onNext={() => goBackHome()} FormFields={MySocioEconomicForm} d={{ ...data, formIndex: 2 }} />
            </MyTabPanel>
          </>
        )}
      </Box>
    </Container>
  );
};

export default TipCasesAddScreen;
