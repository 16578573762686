import styles from './styles'
import Card from '@mui/material/Card';

const MyCard = ({ title, ...otherProps}) =>{
    return (
        <Card 
            style={styles.card}
            {...otherProps}
        />
       
    )
}



export default MyCard;
