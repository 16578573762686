import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MySelectList from "../MyInputs/MySelectList";
import MyMultipleSelectList from "../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addSocio, addTransit, getSocios, getTransits, updateSocio, updateTransit } from "../../api/api";
import { Grid } from "@mui/material";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";
import MyAssisatanceType from "../MyInputs/MyAssisatanceType";
import MyYesNoRadioGroup from "../MyInputs/MyYesNoRadioGroup";

const MySocioEconomicForm = ({ d }) => {
  const formName = "SocioEconomicForm";
  const parentFormName = "VictimProfileForm";

  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        v_id: "",
        familyStructure: "",
        livingWith: "",
        violence: "",
        educationLevel: "",
        lastOccupation: [],
      });
    }

    d.setStrings(strings.socio);
    d.setFormName(formName);
    d.setParentFormName(parentFormName);
    d.setIsProfileSet(true);
    d.setGetFormData(() => getSocios);
    d.setPutFormData(() => updateSocio);
    d.setPostFormData(() => addSocio);

    d.setIsFirstForm(false);
    d.setRequiredId("v_id");
  }, []);

  // Effect to update profile.v_id whenever victimId changes
  useEffect(() => {
    // console.log("transist form victimId updating to ", d.victimId);
    d.setProfile((prevProfile) => ({
      ...prevProfile,
      v_id: d.victimId,
    }));
  }, [d.victimId]);

  if (!d.isProfileSet) return <div></div>;

  return (
    <div>
      <Grid container>
        <MyFormsGridItem>
          <MySelectList d={{ name: "familyStructure", ...d, dbLookupName: "familyStructures" }} />
          <MySelectList d={{ name: "livingWith", ...d, dbLookupName: "livingWiths" }} />
          <MyMultipleSelectList d={{ name: "lastOccupation", ...d, dbLookupName: "occupations" }} />
        </MyFormsGridItem>
        <MyFormsGridItem>
          <MyYesNoRadioGroup d={{ name: "violence", ...d }} />
          <MySelectList d={{ name: "educationLevel", ...d, dbLookupName: "educationLevels" }} />
        </MyFormsGridItem>
      </Grid>
    </div>
  );
};

export default MySocioEconomicForm;
