import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../../MyInputs/MyTextInput";
import { AuthContext } from "../../../App";
import MyCard from "../../MyInputs/MyCard";
import MyDatePickerInput from "../../MyInputs/MyDatePicker";
import MySelectList from "../../MyInputs/MySelectList";
import MyMultipleSelectList from "../../MyInputs/MyMultipleSelectList";
import { addSomAssistanceMultiVictim, addSomMultiVictim, fetchSomAssistanceMultiVictimDetails, getSomMultiVictims, updateSomAssistanceMultiVictim, updateSomMultiVictim } from "../../../api/api";
import MyFormsGridItem from "../../MyInputs/MyFormsGridItem";
import MyMultipleTextInput from "../../MyInputs/MyMultipleTextInput";
import { Box, Grid } from "@mui/material";
import styles from "../../MyInputs/styles";
import MyCardTitle from "../../MyInputs/MyCardTitle";

const MySomAssistanceMultipleVictimProfileForm = ({ d }) => {
  const formName = "SomAssistanceMultipleVictimProfileForm";
  const parentFormName = "CaseDataForm";

  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        numberOfVictims: "",
        ages: {},
        genders: {},
        races: {},
        languages: {},
        countries: [],
        interviewDate: "",
        interviewCountry: "",
        interviewerLocation: "",
        additionalRemarks: "",
        latitude: "",
        longitude: "",

      })
    }

    d.setStrings(strings.victimBulk)
    d.setFormName(formName)
    d.setParentFormName(parentFormName)
    d.setSetShouldSetVictimId(true);

    d.setIsProfileSet(true);
    d.setGetFormData(() => fetchSomAssistanceMultiVictimDetails)
    d.setPutFormData(() => updateSomAssistanceMultiVictim)
    d.setPostFormData(() => addSomAssistanceMultiVictim)



  }, []);


  const [ages, setAges] = useState([
    { key: "<13", value: "<13" },
    { key: "13-18", value: "13-18" },
    { key: "18-25", value: "18-25" },
    { key: "25-45", value: "25-45" },
    { key: ">45", value: ">45" },
  ]);

  const handleNestedChange = (name, key, value) => {
    d.setProfile({ ...d.profile, [name]: { ...d.profile[name], [key]: value } });
  };

  d["handleNestedChange"] = handleNestedChange;

  if (!d.isProfileSet) return <div></div>


  return (
    <div>
      <Box height={64} />

      <Grid container sx={styles.exploitationBorders}>
        <MyFormsGridItem>
          <MyCard>
            <MyTextInput d={{ name: "numberOfVictims", ...d }} inputMode="number" inputProps={{ min: 0 }} />
          </MyCard>

        </MyFormsGridItem>
        <MyFormsGridItem>
          <MyCard>
            <MyMultipleSelectList d={{ name: "countries", ...d, dbLookupName: "countrys" }} />
          </MyCard>

        </MyFormsGridItem>
      </Grid>

      <Grid container sx={styles.exploitationBorders}>
        <MyFormsGridItem>
          <MyCard>
            <MyMultipleTextInput d={{ name: "genders", ...d }} />
          </MyCard>

        </MyFormsGridItem>
        <MyFormsGridItem>
          <MyCard>
            <MyMultipleTextInput d={{ name: "ages", ...d }} overrideOptions={ages} />
          </MyCard>

        </MyFormsGridItem>
      </Grid>

      <Grid container sx={styles.exploitationBorders}>
        <MyFormsGridItem>
          <MyCard>
            <MyMultipleTextInput d={{ name: "races", ...d }} />
          </MyCard>

        </MyFormsGridItem>
        <MyFormsGridItem>
          <MyCard>
            <MyMultipleTextInput d={{ name: "languages", ...d }} />
          </MyCard>

        </MyFormsGridItem>
      </Grid>


    </div>
  );
};

export default MySomAssistanceMultipleVictimProfileForm;
