import { openDB } from 'idb';

let dbPromise;
const defaultObjectStore = 'store';

// Initialize the database (only once)
const initDB = async () => {
  if (!dbPromise) {
    dbPromise = openDB('my-database', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(defaultObjectStore)) {
          db.createObjectStore(defaultObjectStore);
        }
      },
    });
  }
  return dbPromise;
};

export const storeDataSecure =  async (key, value) => {
  // await SecureStore.setItemAsync(key, value);
  await storeData(key, value);
}

export const retrieveDataSecure = async (key) => {
  return await retrieveData(key);
  let result = await SecureStore.getItemAsync(key);
  if (result) {
    // alert("🔐 Here's your value 🔐 \n" + result);
    return result;
  } else {
    // alert('No values stored under that key.');
    return null;
  }
}

export const storeData = async (key, value) => {
  try {
    const db = await initDB();
    await db.put(defaultObjectStore, value, key);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveData = async (key) => {
  try {
    const db = await initDB();
    return await db.get('store', key);
  } catch (error) {
    // Error retrieving data
  }
};

// Remove data
export const removeData = async (key) => {
  try {
    const db = await initDB();
    await db.delete('store', key); // Remove the data by key
    return true;
  } catch (error) {
    return false;
  }
};


export const clearData = async () => {
  try {
    await removeData('jwt_token')
    await removeData('user_pin')
    // await AsyncStorage.clear();
    // await SecureStore.deleteItemAsync('jwt_token');
    // await SecureStore.deleteItemAsync('user_pin');
    
  } catch (error) {
    // Error saving data
  }
};

export const clearOfflineModeVariables = (setGlobalAppVariables, formName) => {
  setGlobalAppVariables((prev) => ({ ...prev, 
    'offlineIDs':{[formName]: null}})
  );
  setGlobalAppVariables((prev) => ({ ...prev, 
    'isOfflineMode':{[formName]: null}})
  );
}

export const setOfflineModeVariables = (setGlobalAppVariables, formName, id) => {
  setGlobalAppVariables((prev) => ({
    ...prev,
    offlineIDs: {
      ...prev.offlineIDs,
      [formName]: id,
    },
    isOfflineMode: {
      ...prev.isOfflineMode,
      [formName]: true,
    },
  }));
};

export const getOfflineModeVariables = (globalAppVariables, formName) => {
  // console.log('gh'+globalAppVariables);
  return {
    offlineID: globalAppVariables?.['offlineIDs']?.[formName],
    isOfflineMode: globalAppVariables?.['isOfflineMode']?.[formName],
  }

};

export const saveOfflineData = async (key, subKey, data) => {
  try {
    console.log('saving '+key+'  '+subKey+ '  '+data);
    // Retrieve existing data
    const jsonValue = await AsyncStorage.getItem('OfflineSyncData');
    let existingData = jsonValue != null ? JSON.parse(jsonValue) : {};

    // Update the data
    if (!existingData[key]) {
      existingData[key] = {};
    }
    existingData[key] = {
      ...existingData[key],
      [subKey]: data,
    };

    // Save the updated data back to storage
    await storeData('OfflineSyncData', existingData);
  } catch (e) {
    // Handle error
    console.error('Error saving data', e);
  }

  Alert.alert('Data Saved', 'The data has been saved offline.\nUpload it when you are online.');

};

export const deleteOfflineUploadedData = async (key) => {
  try {
    // Retrieve existing data
    const jsonValue = await AsyncStorage.getItem('OfflineSyncData');
    let existingData = jsonValue != null ? JSON.parse(jsonValue) : {};

    // Delete the data
    if (existingData[key]) {
      delete existingData[key]
    }

    // Save the updated data back to storage
    await storeData('OfflineSyncData', existingData);

    const jsonValue2 = await AsyncStorage.getItem('OfflineSyncData');
    let existingData2 = jsonValue2 != null ? JSON.parse(jsonValue2) : {};

  } catch (e) {
    // Handle error
    console.error('Error saving data', e);
  }
}

export const retrieveOfflineData = async () => {
  return await retrieveData('OfflineSyncData')
}
