import React, { useContext, useEffect, useState, useRef } from "react";
// import {
//   clearOfflineModeVariables,
//   getOfflineModeVariables,
//   saveOfflineData,
//   setOfflineModeVariables,
// } from "../../utils/offlineStorage";
import { AuthContext } from "../../App";
import { globalUpdateFormData, handleDataValidation } from "../../utils/inputFieldValidations";
import MySnackBar from "../MyInputs/MySnackBar";
import { getJsonFromProfile, getProfileFromJson } from "../../utils/myFunctions";
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import styles from "../MyInputs/styles";
import MyCard from "../MyInputs/MyCard";
import MyLoadingBar from "../MyLoadingBar";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_KEY } from "../../api/api";

const MyGeneralForm = ({ onNext, FormFields, d }) => {
  const { strings, dbLookups, globalAppVariables, language, setAppLanguage, interviewer } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");
  const onDismissProgressBar = () => {
    setProgressBarVisible(false);
  };

  const [isReCaptchaSet, setIsReCaptchaSet] = useState(true);

  const [getFormData, setGetFormData] = useState();
  const [putFormData, setPutFormData] = useState();
  const [postFormData, setPostFormData] = useState();
  const [formUploaded, setFormUploaded] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [formStrings, setFormStrings] = useState();
  const [formName, setFormName] = useState();
  const [formId, setFormId] = useState(d.formId);
  const [parentFormName, setParentFormName] = useState("");
  const [isFirstForm, setIsFirstForm] = useState(false);
  const [isRegistrationForm, setIsRegistrationForm] = useState();
  const [localIsCreateMode, setLocalIsCreateMode] = useState(false);
  const [requiredId, setRequiredId] = useState();
  const [profile, setProfile] = useState({});
  const [isProfileSet, setIsProfileSet] = useState(false);

  const [shouldSetVictimId, setSetShouldSetVictimId] = useState();
  const [shouldSetCaseId, setSetShouldSetCaseId] = useState();

  // const setVictimId = (id) => {
  //   d.setVictimId(id);
  // }

  const [victimOrCaseId, setVictimOrCaseId] = useState(
    interviewer?.data_entry_purpose?.id == 1 || interviewer?.data_entry_purpose?.id == 4 ? d.victimId : d.caseId
  );

  const scrollViewRef = useRef();
  const scrollViewHeight = useRef(0);

  const updateFormData = (name, value) => {
    globalUpdateFormData(name, value, profile, formValidation, setProfile, setFormValidation);
  };

  useEffect(() => {
    if (d.isUpdateMode || (d.isViewMode && getFormData)) {
      const loadData = async () => {
        try {
          setLoading(true);
          let data = await getFormData(victimOrCaseId, formId);

          console.log(data);
          // if form does not exist, switch to isCreateMode to create a new one
          if (!data) {
            setLocalIsCreateMode(true);
          } else {
            setFormId(data?.id);
            data = getProfileFromJson(profile, data, formName);
            console.log("", formName, data);
            setProfile(data);
            setIsProfileSet(true);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      loadData();
    }
  }, [d.isUpdateMode || d.isViewMode, getFormData]);

  useEffect(() => {
    if (d.isUpdateMode || d.isViewMode) return;
    if (globalAppVariables.location) {
      // console.log("updating lat long");
      updateFormData("latitude", globalAppVariables.location?.coords?.latitude);
      updateFormData("longitude", globalAppVariables.location?.coords?.longitude);
    }
  }, [globalAppVariables.location, d.updateLocation]);

  // const showSnackBar = (message, severity) => {
  //   setProgressBarMessage(message);
  //   setProgressBarSeverity(severity);
  //   setProgressBarVisible(true);
  // };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?hl=${language}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [language, d.setCaptcha]);


  const handleSubmit = async () => {
    console.log(profile);
    if (!(await handleDataValidation(profile, formName, strings, formValidation, setFormValidation))) {
      d.showSnackBar(strings.dataValidations.checkFormErrors, "info");
      return;
    }

    if(d.setCaptcha && !isReCaptchaSet){
      setProgressBarMessage(strings.dataValidations.solveCaptcha);
      setProgressBarSeverity("error");
      setProgressBarVisible(true);
      return
    }

    // convert profile camel cases to server json values format (snake_case)
    const profileJson = getJsonFromProfile(profile, formName);
    console.log(profileJson);

    try {
      // setProgressBarMessage('Form is well validated!! I am to implememnt uploading it now');

      setLoading(true);
      d.showSnackBar(strings.dataValidations.pleaseWait, "info");
      let data;

      if (d.isUpdateMode && !localIsCreateMode) {
        // console.log("PUT request->", formId);
        data = await putFormData(profileJson, victimOrCaseId, formId);
      } else {
        if (requiredId && !profile[requiredId]) {
          d.showSnackBar(strings.notifications.fillPreviousForms, "warning");

          return;
        }

        data = await postFormData(profileJson);
        console.log(data);

        // update victim and case Id for other forms in this tablist to use while uploading
        if (shouldSetVictimId) d.setVictimId(data.id);
        if (shouldSetCaseId) d.setCaseId(data.id);

        if (isRegistrationForm) {
          if (data?.status === 201) {
            onNext();
          } else {
            d.showSnackBar(data?.data?.message, "error");
          }
          return;
        } else {
          setFormUploaded(true);
        }
      }

      d.setFormsUploaded({ ...d.formsUploaded, [d.formIndex]: true });
      d.showSnackBar(strings.notifications.uploadSuccess, "success");

      // Handle response...
      onNext(); // Proceed to next form if needed
    } catch (error) {
      // Handle error...
      console.log(error);
    } finally {
      // setProgressBarVisible(false);
      setLoading(false);
    }
  };

  const myComponentData = {
    strings: formStrings,
    formName: formName,
    profile: profile,
    victimId: d.victimId,
    caseId: d.caseId,
    isProfileSet: isProfileSet,
    getFormData: getFormData,
    putFormData: putFormData,
    postFormData: postFormData,
    formValidation: formValidation,
    dbLookups: dbLookups,

    // setters
    setStrings: setFormStrings,
    setFormName: setFormName,
    setProfile: setProfile,
    setIsProfileSet: setIsProfileSet,
    updateFormData: updateFormData,
    setGetFormData: setGetFormData,
    setPutFormData: setPutFormData,
    setPostFormData: setPostFormData,
    setIsFirstForm: setIsFirstForm,
    setRequiredId: setRequiredId,
    setParentFormName: setParentFormName,
    setSetShouldSetVictimId: setSetShouldSetVictimId,
    setSetShouldSetCaseId: setSetShouldSetCaseId,
    setIsRegistrationForm: setIsRegistrationForm,

    scrollViewRef: scrollViewRef,
    scrollViewHeight: scrollViewHeight,
  };

  return (
    <Container style={styles.container} maxWidth={d.maxWidth || "mb"}>
      <MySnackBar message={progressBarMessage} severity={progressBarSeverity} visible={progressBarVisible} onClose={onDismissProgressBar} />
      <MyLoadingBar loading={loading} />

      <div style={{ pointerEvents: d.isViewMode || formUploaded ? "none" : "auto" }}>
        <FormFields d={{ ...myComponentData }} />
      </div>

      {d.isViewMode || formUploaded ? null : (
        // <MyMapView d={{ name: "latitude", ...myComponentData }} />
        <>
          {d.setCaptcha &&
            <ReCAPTCHA style={styles.submitButtonBox}
              key={language}
              sitekey={GOOGLE_RECAPTCHA_KEY}
              hl={language}
              onChange={setIsReCaptchaSet}
            />
          }
          <Box sx={styles.submitButtonBox}>
            <Button variant="contained" icon="account-arrow-right" uppercase="true" onClick={handleSubmit} disabled={loading}>
              {strings.app.saveAndProceed}
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default MyGeneralForm;
