import React, { useContext, useState } from "react";
import { AuthContext } from "../../App";
import { navigationRoutes } from "../../utils/variables";
import { searchSomVictims, searchVictims } from "../../api/api";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MySnackBar from "../MyInputs/MySnackBar";
import MyLoadingBar from "../MyLoadingBar";
import MyCard from "../MyInputs/MyCard";
import MyTextInput from "../MyInputs/MyTextInput";
import MySelectList from "../MyInputs/MySelectList";
import MyDatePickerInput from "../MyInputs/MyDatePicker";
import MyCardTitle from "../MyInputs/MyCardTitle";
import styles from "../MyInputs/styles";
import { Search } from "@mui/icons-material";
import MyVictimCard from "../MyVictimCard";
import MyVictimSearchCard from "../MyVictimSearchCard";
import { useNavigate } from "react-router-dom";

const MyGridItem = ({ children }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} mb={6} sx={{ m: 0 }}>
      {children}
    </Grid>
  )
}

const MySomVictimSearchForm = ({ navigation }) => {
  const [searchParams, setSearchParams] = useState({
    initials: "",
    caseNumber: "",
    gender: "",
    race: "",
    email: "",
    citizenship: "",
    interviewerCountry: "",
    idNumber: "",
    dateOfArrest: "",
    address: "",
    lastPlaceOfResidence: "",
    interviewDate: "",
    interviewerLocation: "",
    dateStart: "", // New
    dateEnd: "", // New
  });
  const { strings, setVictimId, dbLookups } = useContext(AuthContext);

  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");
  const onDismissProgressBar = () => {
    setProgressBarVisible(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await searchSomVictims(searchParams);
      // console.log(response)
      setSearchResults(response);
      if (response?.length === 0) {
        setProgressBarMessage(strings.notifications.noSearchResultsFindHelp);
        setProgressBarVisible(true);
        setProgressBarSeverity("info");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleViewPressed = (victim, caseData, isSomSingleVictim) => {
    navigate(navigationRoutes.somCasesNew, { state: { victim: victim, caseData: caseData, isSomSingleVictim: isSomSingleVictim, isViewMode: true } });

  };
  const handleModifyPressed = (victim, caseData, isSomSingleVictim) => {
    navigate(navigationRoutes.somCasesNew, { state: { victim: victim, caseData: caseData, isSomSingleVictim: isSomSingleVictim, isUpdateMode: true } });

  };

  const myComponentData = {
    strings: strings.victim,
    profile: searchParams,
    dbLookups: dbLookups,
    formValidation: null,
    updateFormData: handleChange,
  };
  return (
    <Container style={styles.container} maxWidth="md">
      <MySnackBar message={progressBarMessage} severity={progressBarSeverity} visible={progressBarVisible} onClose={onDismissProgressBar} />
      <MyLoadingBar loading={loading} />


      {/* <Button
        color={theme.colors.primary}
        title="Advanced Search"
        onPress={() => setShowAdvancedSearch(!showAdvancedSearch)}
      /> */}
      <MyCard>
        <MyCardTitle title={strings.victim.searchSomVictims} />

        <Grid container>
          <MyGridItem><MyTextInput d={{ name: "initials", ...myComponentData }} /></MyGridItem>
          {/* <MyGridItem><MyTextInput d={{ name: "idNumber", ...myComponentData }} /></MyGridItem> */}
          <MyGridItem><MySelectList d={{ name: "citizenship", ...myComponentData, dbLookupName: "countrys" }} /></MyGridItem>
          <MyGridItem><MySelectList d={{ name: "interviewerCountry", ...myComponentData, dbLookupName: "countrys" }} /></MyGridItem>
          <MyGridItem></MyGridItem>
          <MyGridItem><MyDatePickerInput d={{ name: "dateStart", ...myComponentData }} /></MyGridItem>
          <MyGridItem><MyDatePickerInput d={{ name: "dateEnd", ...myComponentData }} /></MyGridItem>
          {/* <MySelectList d={{ name: "race", ...myComponentData, dbLookupName: "races" }} />
          <MySelectList d={{ name: "gender", ...myComponentData, dbLookupName: "genders" }} />
          <MySelectList d={{ name: "lastPlaceOfResidence", ...myComponentData, dbLookupName: "countrys" }} /> */}

          {/* <SubmitButton style={styles.sbm} textColor={theme.colors.primary} uppercase={true} onPress={handleSearch}>
            {strings.app.search}
          </SubmitButton> */}
        </Grid>

        <Box sx={styles.searchButtonBox}>
          <Button
            variant="contained"
            icon="account-arrow-right"
            startIcon={<Search />}
            onClick={handleSearch}
            disabled={loading}
          >
            {strings.app.search}
          </Button>
        </Box>

      </MyCard>

      <Grid container spacing={2}>
        {searchResults?.map(victim => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={victim.id}>
            <MyVictimSearchCard
              victim={victim}
              viewPressed={handleViewPressed}
              modifyPressed={handleModifyPressed}
            />
          </Grid>
        ))}
      </Grid>

    </Container>
  );
};


export default MySomVictimSearchForm;
