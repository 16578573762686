import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MyDatePickerInput from "../MyInputs/MyDatePicker";
import MySelectList from "../MyInputs/MySelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addInterviewer, fetchInterviewerDetails, updateInterviewerDetails } from "../../api/api";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Lock, Login } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import styles from "../MyInputs/styles";
import imageLogo from "../../assets/icon.png";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";

const MyRegistrationForm = ({ d }) => {
  const formName = "RegistrationForm";
  const { strings, interviewer } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        firstName: "",
        familyName: "",
        position: "",
        organization: "",
        address: "",
        email: "",
        // country: "",
        dataEntryPurpose: "",
        password: "",
        confirmPassword: "",
      });
    }

    d.setStrings(strings.profileForm);
    d.setFormName(formName);
    d.setIsRegistrationForm(true);

    d.setIsProfileSet(true);
    d.setGetFormData(() => fetchInterviewerDetails);
    d.setPutFormData(() => updateInterviewerDetails);
    d.setPostFormData(() => addInterviewer);
  }, []);

  useEffect(() => {
    d.setStrings(strings.profileForm);
  }, [strings.profileForm]);

  if (!d.isProfileSet) return <div></div>;

  return (
    <div>
      <MyCard>
        <Box sx={styles.logoBox}>
          <img src={imageLogo} width={150} alt={""} loading="lazy" />
        </Box>

        <MyCardTitle title={strings.profileForm.interviewerRegistration} />

        <Grid container>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "firstName", ...d }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "familyName", ...d }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "position", ...d }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "organization", ...d }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "address", ...d }} />
          </MyFormsGridItem>
          {/* <MyFormsGridItem>
            <MySelectList d={{ name: "country", ...d, dbLookupName: "countrys" }} />
          </MyFormsGridItem> */}
        </Grid>
      </MyCard>
      <MyCard>
        <Grid container>
          {/* <MyFormsGridItem>
            <MySelectList d={{ name: "dataEntryPurpose", ...d, dbLookupName: "dataEntryPurposes" }} />
          </MyFormsGridItem> */}
          <MyFormsGridItem>
            <MyTextInput d={{ name: "email", ...d }} inputMode="email" />
          </MyFormsGridItem>
          <MyFormsGridItem>
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput
              d={{ name: "password", ...d }}
              inputMode={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput
              d={{ name: "confirmPassword", ...d }}
              inputMode={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MyFormsGridItem>
        </Grid>
      </MyCard>
    </div>
  );
};

export default MyRegistrationForm;
