import { fetchDbLookups } from "../api/api";
import defaultDbLookups from "../i18n/db-lookups/defaultDbLookups";
import { retrieveData, storeData } from "./offlineStorage";

export const updateDbLookups = async () => {
    try {
        const data = await fetchDbLookups('current-epoch-here')
        await storeData('dbLookups', data.dbLookups)
        await storeData('findHelps', data.findHelps)
        // //console.log(data)

        return true;
    } catch (error) {
        // Error updating db lookup
        //console.log(error)
        return false;
    }
};

export const retrieveDbLookup = async (lang) => {
    try {
        const data = await retrieveData('dbLookups');

        // Check if data is not null/undefined and has the requested language key
        if (data && data[lang]) {
            return data[lang];
        } else {
            throw new Error('Data or language not found'); // Force to use DefaultDbLookups
        }
    } catch (error) {
        // if no data was gotten from local storage, then use initial hardcoded db
        return defaultDbLookups['dbLookups'][lang];
    }
};

export const retrieveFindHelps = async (lang) => {
    try {
        const data = await retrieveData('findHelps');
        if (data && data[lang]) {
            return data[lang];
        } else {
            throw new Error('Data or language not found'); // Force to use DefaultDbLookups
        }
    } catch (error) {
        // if no data was gotten from local storage, then use initial hardcoded db
        return defaultDbLookups['findHelps'][lang];

    }
}

