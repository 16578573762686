import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MySelectList from "../MyInputs/MySelectList";
import MyMultipleSelectList from "../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addAssistance, addTransit, getAssistances, getTransits, updateAssistance, updateTransit } from "../../api/api";
import { Grid } from "@mui/material";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";
import MyAssisatanceType from "../MyInputs/MyAssisatanceType";

const MyAssistanceTypesForm = ({ d }) => {
  const formName = "AssistanceTypesForm";
  const parentFormName = "VictimProfileForm";

  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        v_id: "",
        social_assistance_duration: "",
        social_assistance_duration_time_unit: "",
        social_assistance_providers: [],
        social_assistance_providers_other: "",
    
        medical_rehabilitation_assistance_duration: "",
        medical_rehabilitation_assistance_duration_time_unit: "",
        medical_rehabilitation_assistance_providers: [],
        medical_rehabilitation_assistance_providers_other: "",
    
        halfway_house_assistance_duration: "",
        halfway_house_assistance_duration_time_unit: "",
        halfway_house_assistance_providers: [],
        halfway_house_assistance_providers_other: "",
    
        vocational_training_assistance_duration: "",
        vocational_training_assistance_duration_time_unit: "",
        vocational_training_assistance_providers: [],
        vocational_training_assistance_providers_other: "",
    
        legal_assistance_duration: "",
        legal_assistance_duration_time_unit: "",
        legal_assistance_providers: [],
        legal_assistance_providers_other: "",
    
        financial_assistance_duration: "",
        financial_assistance_duration_time_unit: "",
        financial_assistance_providers: [],
        financial_assistance_providers_other: "",
    
        immigration_assistance_duration: "",
        immigration_assistance_duration_time_unit: "",
        immigration_assistance_providers: [],
        immigration_assistance_providers_other: "",
    
        housing_allowance_duration: "",
        housing_allowance_duration_time_unit: "",
        housing_allowance_providers: [],
        housing_allowance_providers_other: "",
    
        shelter_assistance_duration: "",
        shelter_assistance_duration_time_unit: "",
        shelter_assistance_providers: [],
        shelter_assistance_providers_other: "",
    
        income_generating_project_assistance_duration: "",
        income_generating_project_assistance_duration_time_unit: "",
        income_generating_project_assistance_providers: [],
        income_generating_project_assistance_providers_other: "",
    
        medical_assistance_duration: "",
        medical_assistance_duration_time_unit: "",
        medical_assistance_providers: [],
        medical_assistance_providers_other: "",
    
        education_assistance_duration: "",
        education_assistance_duration_time_unit: "",
        education_assistance_providers: [],
        education_assistance_providers_other: "",
    
        community_based_assistance_duration: "",
        community_based_assistance_duration_time_unit: "",
        community_based_assistance_providers: [],
        community_based_assistance_providers_other: "",
      });
    }

    d.setStrings(strings.assistance);
    d.setFormName(formName);
    d.setParentFormName(parentFormName);
    d.setIsProfileSet(true);
    d.setGetFormData(() => getAssistances);
    d.setPutFormData(() => updateAssistance);
    d.setPostFormData(() => addAssistance);

    d.setIsFirstForm(false);
    d.setRequiredId("v_id");
  }, []);

  // Effect to update profile.v_id whenever victimId changes
  useEffect(() => {
    // console.log("transist form victimId updating to ", d.victimId);
    d.setProfile((prevProfile) => ({
      ...prevProfile,
      v_id: d.victimId,
    }));
  }, [d.victimId]);

  if (!d.isProfileSet) return <div></div>;

  return (
    <div>
      <Grid container>
        <MyFormsGridItem>
          <MyAssisatanceType d={{ ...d, name: "social_assistance", stringName: "socialAssistance" }} />
          <MyAssisatanceType d={{ ...d, name: "housing_allowance", stringName: "housingAllowance" }} />
          <MyAssisatanceType d={{ ...d, name: "shelter_assistance", stringName: "shelter" }} />
          <MyAssisatanceType d={{ ...d, name: "income_generating_project_assistance", stringName: "incomeGeneratingProject" }} />
          <MyAssisatanceType d={{ ...d, name: "medical_assistance", stringName: "medicalAssistance" }} />
          <MyAssisatanceType d={{ ...d, name: "education_assistance", stringName: "educationAssistance" }} />
          <MyAssisatanceType d={{ ...d, name: "community_based_assistance", stringName: "communityBasedAssistance" }} />
        </MyFormsGridItem>
        <MyFormsGridItem>
          <MyAssisatanceType d={{ ...d, name: "medical_rehabilitation_assistance", stringName: "medicalRehabilitationAssistance" }} />
          <MyAssisatanceType d={{ ...d, name: "halfway_house_assistance", stringName: "halfwayHouse" }} />
          <MyAssisatanceType d={{ ...d, name: "vocational_training_assistance", stringName: "vocationalTraining" }} />
          <MyAssisatanceType d={{ ...d, name: "legal_assistance", stringName: "legalAssistance" }} />
          <MyAssisatanceType d={{ ...d, name: "financial_assistance", stringName: "financialAssistance" }} />
          <MyAssisatanceType d={{ ...d, name: "immigration_assistance", stringName: "immEmmigrationAssistance" }} />
        </MyFormsGridItem>
      </Grid>
    </div>
  );
};

export default MyAssistanceTypesForm;
