import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../../MyInputs/MyTextInput";
import { AuthContext } from "../../../App";
import MyCard from "../../MyInputs/MyCard";
import MySelectList from "../../MyInputs/MySelectList";
import MyMultipleSelectList from "../../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../../MyInputs/MyCardTitle";
import { addSomTransit, fetchSomTransitDetails, getSomTransits, updateSomTransit } from "../../../api/api";
import { Grid } from "@mui/material";
import MyFormsGridItem from "../../MyInputs/MyFormsGridItem";

const MySomSingleVictimTransistRouteForm = ({ d }) => {
  const formName = "SomTransitForm";
  const parentFormName = "CaseDataForm";

  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        v_id: "",
        countryOfOrigin: "",
        countryOfDestination: "",
        cityVillageOfDest: "",
        cityVillageOfOrigin: "",
        countriesOfTransit: [],
        countryOfInterception: "",
        meansOfTransportation: [],
        remarks: "",
      })
    }

    d.setStrings(strings.somTransit)
    d.setFormName(formName)
    d.setParentFormName(parentFormName)

    d.setIsProfileSet(true);
    d.setGetFormData(() => getSomTransits)
    d.setPutFormData(() => updateSomTransit)
    d.setPostFormData(() => addSomTransit)

    d.setIsFirstForm(false);
    d.setRequiredId("v_id");


  }, []);

  // Effect to update profile.v_id whenever victimId changes
  useEffect(() => {
    // console.log("transist form victimId updating to ", d.victimId);
    d.setProfile((prevProfile) => ({
      ...prevProfile,
      v_id: d.victimId,
    }));
  }, [d.victimId]);


  if (!d.isProfileSet) return <div></div>

  return (
    <div>
      <MyCard>
        <Grid container>
          <MyFormsGridItem>
            <MySelectList d={{ name: "countryOfOrigin", ...d, dbLookupName: "countrys" }} />
            <MySelectList d={{ name: "countryOfDestination", ...d, dbLookupName: "countrys" }} />
            <MyMultipleSelectList
              d={{ name: "meansOfTransportation", ...d, dbLookupName: "transportMeans" }}
            />

          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "cityVillageOfOrigin", ...d }} />
            <MyTextInput d={{ name: "cityVillageOfDest", ...d }} />
            <MyTextInput
              d={{ name: "remarks", ...d, stringName: "additionalRemarks" }}
              multiline
              rows={4}
            />
          </MyFormsGridItem>
        </Grid>
      </MyCard>

    </div>
  );
};

export default MySomSingleVictimTransistRouteForm;
