import React from 'react';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

function MyFormsCount({ label, count }) {
  if (!count) return
  return (
    <Typography >
      {`${label}: `}
      {count.length > 0 ? (
        <>
          {count.length}
          <CheckCircleIcon sx={{ color: 'green', verticalAlign: 'middle' }} />
        </>
      ) : (
        <ErrorIcon sx={{ ml: 0.5, color: 'red', verticalAlign: 'middle' }} />
      )}
    </Typography>
  );
}

export default MyFormsCount;
