import React, { useState, useEffect, useRef } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import styles from "./styles";
import { scrollToComponent } from "../../utils/inputFieldValidations";
import MyCard from "./MyCard";
import MyCardTitle from "./MyCardTitle";
import MyTextInput from "./MyTextInput";
import MySelectList from "./MySelectList";
import MyMultipleSelectList from "./MyMultipleSelectList";

const MyAssisatanceType = ({ d, ...otherProps }) => {
  if (!d) return <></>;
  var stringName = d.stringName || d.name;

  return (
    <MyCard>
      <MyCardTitle title={d.strings[stringName]} />
      <MyTextInput
        d={{
          ...d,
          name: `${d.name}_duration`,
          stringName: "duration",
          optionsList: { dbLookupName: "timeUnits", side: "right" },
        }}
        inputMode={"number"}
        inputProps={{ min: 0 }}
      />
      <MyMultipleSelectList d={{ ...d, name: `${d.name}_providers`, stringName: "providedBy", dbLookupName: "providers" }} />
    </MyCard>
  );
};

export default MyAssisatanceType;
