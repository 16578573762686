// screens/LoginScreen.js
import React, { useContext, useState } from "react";
import { theme } from "../utils/variables";
import { AuthContext } from "../App";
import { updateDbLookups } from "../utils/updateDbLookups";
import { retrieveData, storeData } from "../utils/offlineStorage";
import { Autocomplete, Box, Container, TextField } from "@mui/material";
import MySnackBar from "../components/MyInputs/MySnackBar";
import MyCard from "../components/MyInputs/MyCard";
import imageLogo from "../assets/icon.png";
import styles from "../components/MyInputs/styles";
import MyInterviewerProfileForm from "../components/MyForms/MyInterviewerProfileForm";
import MyGeneralForm from "../components/MyForms/MyGeneralForm";
import MyLoadingBar from "../components/MyLoadingBar";
import { fetchInterviewerDetails } from "../api/api";

const SettingsScreen = ({ navigation }) => {
  const { strings, setAppLanguage, setInterviewer } = useContext(AuthContext);
  const languages = [
    { key: "en", value: "English" },
    { key: "fr", value: "French" },
    { key: "pt", value: "Portuguese" },
  ];
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");
  const onDismissProgressBar = () => { setProgressBarVisible(false); };

  const handleUpdate = async () => {
    setProgressBarMessage(strings.dataValidations.pleaseWait);
    setProgressBarVisible(true);
    try {
      const updated = await updateDbLookups();
      if (updated) {
        const lang = await retrieveData("app_lang");
        await setAppLanguage(lang);

        Alert.alert(strings.notifications.successHeader, strings.notifications.appDataUpdatedSuccess);

      } else {
        // Alert.alert("Update Failed", "Please try again later");
      }
    } catch (error) {
      // console.log(error)
      // Alert.alert("Update Failed", "Please try again later");
    }
    setProgressBarVisible(false);
  };

  const showSnackBar = (message, severity) => {
    setProgressBarMessage(message);
    setProgressBarSeverity(severity);
    setProgressBarVisible(true);
  };

  const refreshInterviewerData = async () => {
    try {
      setLoading(true);
      let data = await fetchInterviewerDetails();

      console.log(data);
      if (data) {
        setInterviewer(data);
        await storeData('interviewer', data);

      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const data = {
    isUpdateMode: true,
    setFormsUploaded: refreshInterviewerData,
    showSnackBar: showSnackBar,
  };


  return (
    <Container>
      <Container maxWidth="sm">
        <MySnackBar message={progressBarMessage} severity={progressBarSeverity} visible={progressBarVisible} onClose={onDismissProgressBar} />
        <MyLoadingBar loading={loading} />
        <Box sx={styles.settingsBox}>

          <MyCard>
            <Box sx={styles.logoBox}>
              <img
                src={imageLogo}
                width={150}
                alt={''}
                loading="lazy"
              />
            </Box>

            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                console.log(newValue);
                setValue(newValue)
                if (newValue?.key) setAppLanguage(newValue?.key)
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                console.log("input value", newInputValue);

                setInputValue(newInputValue);
              }}
              options={languages}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => <TextField {...params} label={strings.app.app_language} />}
            />


          </MyCard>
        </Box>
      </Container>
      <Container>
        <Box>
          <MyGeneralForm onNext={() => null} FormFields={MyInterviewerProfileForm} d={{ maxWidth: 'mb', formIndex: 0, ...data }} />
        </Box>
      </Container>
    </Container>

  );
};


export default SettingsScreen;
