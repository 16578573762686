import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../App";
import MyTextInput from "../components/MyInputs/MyTextInput";
import MyCard from "../components/MyInputs/MyCard";
import { Box, Container, Divider, Typography } from "@mui/material";
import imageLogo from "../assets/icon.png";
import styles from "../components/MyInputs/styles";
import MyCardTitle from "../components/MyInputs/MyCardTitle";

const FindHelpScreen = () => {
  const [searchParams, setSearchParams] = useState({
    searchString: "",

  });
  const { strings, findHelps, dbLookups } = useContext(AuthContext);

  const [searchResults, setSearchResults] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [searchError, setSearchError] = useState(false);


  const handleChange = (name, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (searchParams.searchString === "") {
      setSearchResults(null);
      setSelectedResult(null)
      return;
    }
    let results = findHelps.filter((item) => item.search_text?.toLocaleLowerCase().includes(searchParams.searchString.toLocaleLowerCase()));
    // console.log(results);
    // console.log("-->" + searchParams.searchString);
    setSearchResults(results);
    setSelectedResult(null)

    if (results?.length === 0) {
      setSearchError(true);
    } else {
      setSearchError(false);

    }

  }, [searchParams.searchString]);

  const handleViewPressed = (search) => {
    setSelectedResult(search);
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };


  const myComponentData = {
    strings: strings.findHelp,
    profile: searchParams,
    dbLookups: dbLookups,
    formValidation: null,
    updateFormData: handleChange,
  };


  return (
    <Container>
      <MyTextInput
        style={{ margin: 8 }} d={{ name: "searchString", ...myComponentData }}
        error={searchError}
      />
      {searchError ? <Typography style={{ color: 'red', marginLeft: 16 }}>{strings.notifications.noSearchResultsFindHelp}</Typography> : null}
      <div>

        {selectedResult ? (
          <Box style={styles.webViewContainer} sx={{mb: 8}}>
            <MyCard>
              <MyCardTitle title={selectedResult.search_text} />
              <Divider sx={{ mb: 1 }} />
              {/* we set our already sanitized HTML here */}
              <div dangerouslySetInnerHTML={{ __html: selectedResult.search_description }} />
            </MyCard>
          </Box>

        ) : null}

        {searchResults?.length > 0 ? (
          // Display search results here
          <MyCard>
            {searchResults.map((search) => (
              <Box key={search.key} onClick={() => handleViewPressed(search)}>
                <Box style={styles.findHelpListItem}>
                  <Typography>{search.search_text}</Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </MyCard>
        ) : (
          <Box sx={styles.logoBox}>
            <img
              src={imageLogo}
              width={150}
              alt={''}
              loading="lazy"
            />
          </Box>


        )}


      </div>
    </Container>

  );
};


export default FindHelpScreen;
