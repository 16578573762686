// api/auth.js
import axios from 'axios';
import { clearData, storeData, retrieveData, storeDataSecure, retrieveDataSecure } from '../utils/offlineStorage';
import { fetchInterviewerDetails, TOKEN_EXPIRATION_TIME } from './api';
import { API_URL } from './api';

export const checkAuth = async () => {
  try {

    const token = await retrieveDataSecure('jwt_token');
    const interviewer = await retrieveData('interviewer');
    if (token) {
      //console.log(token)

      return { auth: true, interviewer: interviewer };
    }
    return { auth: false, interviewer: null };
  } catch (error) {
    return { auth: false, interviewer: null };
  }
}

export const authenticateUser = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/token/`, { username, password });
    const { access, refresh } = response.data;
    // console.log(response.data);

    await storeDataSecure('jwt_token', access);
    await storeDataSecure('jwt_refresh', refresh);
    await storeData('username', username);

    // //console.log(`Bearer ${access}`)
    const interviewer = await axios.get(API_URL + '/api/interviewers/', {
      headers: {
        Authorization: `Bearer ${access}`
      }
    });
    //console.log(interviewer.data)
    await storeData('interviewer', interviewer.data);

    return { access: access, interviewer: interviewer.data };
  } catch (error) {
    if (error.response?.status == 401) {

      console.error('Password error', error.message);
    }
    //console.log(error);

    // Handle error (invalid credentials, network error, etc.)
    // throw error
  }
};

export const authenticateUserWeb = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/token-web/`, { username, password });
    console.log(response.data);

   
    return response.data;
  } catch (error) {
    if (error.response?.status == 401) {

      console.error('Password error', error.message);
    }
    //console.log(error);

    // Handle error (invalid credentials, network error, etc.)
    // throw error
  }
};

export const authenticateUserWeb2FA = async (username, password, user_id, token) => {
  try {
    const response = await axios.post(`${API_URL}/api/token-verify-2fa/`, { username, password, user_id, token });
    const { access, refresh } = response.data;
    // console.log(response.data);

    await storeDataSecure('jwt_token', access);
    await storeDataSecure('jwt_refresh', refresh);
    await storeData('username', username);

    const interviewer = await axios.get(API_URL + '/api/interviewers/', {
      headers: {
        Authorization: `Bearer ${access}`
      }
    });
    //console.log(interviewer.data)
    await storeData('interviewer', interviewer.data);

    return { access: access, interviewer: interviewer.data };
  } catch (error) {
    if (error.response?.status == 401) {

      console.error('Password error', error.message);
    }
    //console.log(error);

    // Handle error (invalid credentials, network error, etc.)
    // throw error
  }
};


export const refreshToken = async () => {
  try {
    const refresh = await retrieveDataSecure('jwt_refresh');
    const response = await axios.post(`${API_URL}/api/token/refresh/`, { refresh });
    const { access } = response.data;
    await storeDataSecure('jwt_token', access);
   
    const expirationTime = Date.now() + TOKEN_EXPIRATION_TIME;
    await storeDataSecure('jwt_token_expiration', expirationTime.toString());
    return access;
  } catch (error) {
    if (error.response?.status == 401) {

      console.error('Password error', error.message);
    }
    console.error('API request setup error:', error.message);

    // Handle error (invalid credentials, network error, etc.)
    throw error
  }
};

export const storePin = async (pin) => {
  try {
    await storeDataSecure('user_pin', pin);
  } catch (error) {
    // handle the error
  }
};

export const verifyPin = async (pin) => {
  try {
    const storedPin = await retrieveDataSecure('user_pin');
    if (storedPin === null) {
      await storePin(pin)
      return true;
    }
    return storedPin === pin;
  } catch (error) {
    // handle the error
    await storePin(pin)
    return true;
  }
};

export const signout = async () => {
  try {
    await clearData();


    return true;
  } catch (error) {
    // handle the error
    return false;
  }
}