import React, { useRef, useEffect } from "react";
import styles from './styles'
import { TextField, Typography } from "@mui/material";
import { scrollToComponent } from "../../utils/inputFieldValidations";
import MySelectList from "./MySelectList";
// import { scrollToComponent } from "../../utils/inputFieldValidations";

const MyTextInput = ({ d, mode = "outlined", overrideLabel, inputMode, ...otherProps }) => {
  // return (
  //   <TextField
  //     label="Email"
  //     // value={email}
  //     // onChange={e => setEmail(e.target.value)}
  //     variant="outlined"
  //   />
  // )
  var stringName = d.stringName || d.name;
  var label;
  if (overrideLabel) label = overrideLabel;
  else label = d.strings?.[stringName];
  label = d.formValidation?.[d.name]?.["isMandatory"] ? "* " + label : label;

  var value;
  if (d.overrideValue) value = d.overrideValue;
  else value = d.profile?.[d.name];

  var longLabel = label?.length > 50;
  const componentRef = useRef(null);

  const isOptionsSelector = d?.optionsList ? true : false;
  const isOptionsSelectorLeftSide = d?.optionsList?.side === "left";
  const errorsExist = d.formValidation?.[d.name]?.["isMandatory"] || d.formValidation?.[d.name]?.["errors"];

  scrollToComponent(d.formValidation?.[d.name], d.scrollViewRef, componentRef, d.scrollViewHeight);

  return (
    <div style={{ ...(d.noStyling ? {} : styles.MyTextInput), ...(errorsExist ? styles.myTextInputErrors : {}) }} ref={componentRef}>
      {/* {d.formValidation?.[d.name]?.['scrollToComponent'] ? scrollToComponent(d.scrollViewRef, componentRef) : null} */}

      {/* <Text>status={d.formValidation}</Text> */}
      {longLabel ? (
        <Typography style={styles.MyTextLabel}>
          {label}
        </Typography>
      ) : (
        <></>
      )}

      <div style={styles.MyTextInputFlex}>
        {isOptionsSelector && isOptionsSelectorLeftSide ? (
          <div style={styles.MyTextInputFlexInner}>
            <MySelectList
              d={{ ...d, dbLookupName: d.optionsList.dbLookupName, name: `${d.name}Option`, noStyling: true }}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.MyTextInputFlexInner}>
          <TextField
            style={styles.MyInnerTextInput}
            value={String(value || "")}
            label={longLabel ? null : label}
            // error={errorsExist}
            onChange={(e) => d.updateFormData(d.name, e.target.value)}
            variant={mode}
            type={inputMode}
            fullWidth
            {...otherProps}
          />
        </div>

        {isOptionsSelector && !isOptionsSelectorLeftSide ? (
          <div style={styles.MyTextInputFlexInner}>
            <MySelectList
              d={{ ...d, dbLookupName: d.optionsList.dbLookupName, name: `${d.name}Option`, noStyling: true }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {d.formValidation?.[d.name]?.["errors"] ? (
        <Typography style={styles.MyTextLabelError} dangerouslySetInnerHTML={{__html: d.formValidation?.[d.name]?.["errors"]?.["helperText"]?.replace(/\n/g, "<br>")}} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyTextInput;
