import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { AuthContext } from '../App';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyPasswordResetSuccessDialog = ({ open, onClose, onConfirm, description }) => {
  const { strings } = useContext(AuthContext);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose(event);
        }
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle>{strings.login.registrationSuccess}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onClose} color="inherit">{strings.app.cancel}</Button> */}
        <Button onClick={onConfirm} color="primary" autoFocus>{strings.app.login}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MyPasswordResetSuccessDialog;
