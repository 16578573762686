// screens/LoginScreen.js
import React, { useContext, useState } from "react";
import { authenticateUser } from "../api/auth";
import { theme, navigationRoutes } from "../utils/variables";
import { AuthContext } from "../App";
import { Autocomplete, Box, Button, Container, Grid, ImageListItem, Tooltip } from "@mui/material";
import styles from "../components/MyInputs/styles";
import MySnackBar from "../components/MyInputs/MySnackBar";
import imageLogo from "../assets/icon.png";
import MyCard from "../components/MyInputs/MyCard";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { HelpOutline, Lock, Login, PersonAdd, RemoveRedEye } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MyCardTitle from "../components/MyInputs/MyCardTitle";
import MySelectList from "../components/MyInputs/MySelectList";
import { Link, useNavigate } from "react-router-dom";
import MyLoadingBar from "../components/MyLoadingBar";
import { resetPassword, resetPasswordConfirm } from "../api/api";
import MyPasswordResetSuccessDialog from "../components/MyPasswordResetSuccessDialog";

const ForgotPasswordScreen = ({ navigation }) => {
  const { strings, setAppLanguage, setInterviewer, setIsAuthenticated } = useContext(AuthContext);
  const passwordResetSteps = { ENTER_EMAIL: 1, VERIFY_TOKEN: 2, RESET_PASSWORD: 3 };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setComnfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [isTokenValid, setIsTokenValid] = useState();
  const [passwordResetStep, setPasswordResetStep] = useState(passwordResetSteps.ENTER_EMAIL);
  const [passwordErrors, setPasswordErrors] = useState();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();


  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
    navigate(navigationRoutes.login);
  };

  const handleLogoutClose = () => {
    setLogoutDialogOpen(false);
  };

  const goToLogin = () => {
    setLogoutDialogOpen(true);
  };

  const [loading, setLoading] = useState(false);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");
  const onDismissProgressBar = () => {
    setProgressBarVisible(false);
  };

  const handleClick = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (passwordResetStep === passwordResetSteps.ENTER_EMAIL) {
      if (!emailRegex.test(String(username).toLowerCase())) {
        setProgressBarMessage(strings.dataValidations.helperTexts.invalidEmail);
        setProgressBarSeverity("error");
        setProgressBarVisible(true);
        return;
      }

      setLoading(true);
      const data = await resetPassword({ email: username });
      console.log(data);

      if (data.status === 200) {
        setPasswordResetStep(passwordResetSteps.VERIFY_TOKEN);
        setProgressBarMessage(data.data?.message);
        setProgressBarSeverity("success");
        setProgressBarVisible(true);
      } else {
        setProgressBarMessage(data.data?.error);
        setProgressBarSeverity("error");
        setProgressBarVisible(true);
      }
      setLoading(false);
    }

    if (passwordResetStep === passwordResetSteps.VERIFY_TOKEN) {
      if (token.length < 6) {
        setProgressBarMessage(strings.dataValidations.helperTexts.resetTokenInvalid);
        setProgressBarSeverity("error");
        setProgressBarVisible(true);
        return;
      }

      setPasswordResetStep(passwordResetSteps.RESET_PASSWORD);
    }

    if (passwordResetStep === passwordResetSteps.RESET_PASSWORD) {
      const passwordRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])" + // at least one lowercase, one uppercase, one digit, one non-alphanum character
          "(?=.{8,})" // at least 8 characters
      );
      const missing = [];
      if (!password.match(/[a-z]/)) missing.push(strings.dataValidations.helperTexts.passwordALowerCase);
      if (!password.match(/[A-Z]/)) missing.push(strings.dataValidations.helperTexts.passwordAUpperCase);
      if (!password.match(/[0-9]/)) missing.push(strings.dataValidations.helperTexts.passwordADigit);

      const specialChars = /[^\w]/;
      if (!password.match(specialChars)) missing.push(strings.dataValidations.helperTexts.passwordASpecialCharacter);
      if (password.length < 8) missing.push(strings.dataValidations.helperTexts.passwordMinLength);

      if (!passwordRegex.test(password)) {
        setPasswordErrors(`${strings.dataValidations.helperTexts.passwordMustContain} \n*${missing.join("\n*")}`);
        return;
      } else {
        setPasswordErrors(null);
      }

      if (password !== confirmPassword) {
        setPasswordErrors(strings.dataValidations.helperTexts.passwordsMustMatch);
        return;
      } else {
        setPasswordErrors(null);
      }

      setLoading(true);
      const profile = { email: username, token: token, password: password, confirm_password: confirmPassword };
      console.log(profile);
      const data = await resetPasswordConfirm(profile);
      console.log(data);

      if (data.status === 200) {
        setProgressBarMessage(data.data?.message);
        setProgressBarSeverity("success");
        setProgressBarVisible(true);
        setDialogText(data.data?.message);
        goToLogin()
      } else {
        setProgressBarMessage(data.data?.error);
        setProgressBarSeverity("error");
        setProgressBarVisible(true);
      }
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <MySnackBar message={progressBarMessage} visible={progressBarVisible} severity={progressBarSeverity} onClose={onDismissProgressBar} />
      <MyPasswordResetSuccessDialog open={logoutDialogOpen} onClose={handleLogoutClose} onConfirm={handleLogoutConfirm}  description={dialogText} />
      <MyLoadingBar loading={loading} />

      <Box sx={styles.loginBox}>
        <MyCard>
          <Box sx={styles.logoBox}>
            <img src={imageLogo} width={150} alt={""} loading="lazy" />
          </Box>

          <MyCardTitle title={strings.forgotPassword.resetPassword} />

          {passwordResetStep >= passwordResetSteps.ENTER_EMAIL && (
            <TextField
              style={{ marginTop: 10, marginBottom: 10 }}
              label={strings.login.email}
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          )}

          {passwordResetStep >= passwordResetSteps.VERIFY_TOKEN && (
            <TextField
              style={{ marginTop: 10, marginBottom: 10 }}
              label={strings.forgotPassword.token}
              fullWidth
              value={token}
              onChange={(e) => setToken(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Tooltip title={strings.forgotPassword.tokenSentToEmail}>
                    <IconButton onClick={() => alert(strings.forgotPassword.tokenSentToEmail)}>
                      <HelpOutline />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          )}

          <Box height={64} />

          {passwordResetStep >= passwordResetSteps.RESET_PASSWORD && (
            <>
              <TextField
                style={{ marginTop: 10, marginBottom: 10 }}
                type={showPassword ? "text" : "password"}
                label={strings.forgotPassword.password}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Typography style={styles.MyTextLabelError} dangerouslySetInnerHTML={{ __html: passwordErrors?.replace(/\n/g, "<br>") }} />

              <TextField
                style={{ marginTop: 10, marginBottom: 10 }}
                type={showPassword ? "text" : "password"}
                label={strings.forgotPassword.confirmPassword}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={confirmPassword}
                onChange={(e) => setComnfirmPassword(e.target.value)}
              />
            </>
          )}

          <Box mt={4}>
            <Button variant="contained" fullWidth onClick={handleClick} sx={{ textTransform: "none" }} disabled={loading}>
              {passwordResetStep === passwordResetSteps.ENTER_EMAIL
                ? strings.forgotPassword.requestResetToken
                : passwordResetStep === passwordResetSteps.VERIFY_TOKEN
                ? strings.forgotPassword.verifyToken
                : strings.forgotPassword.resetPassword}
            </Button>
          </Box>
        </MyCard>
      </Box>
    </Container>
  );
};

export default ForgotPasswordScreen;
