import { defaultRequiredInputFields } from "./variables";
// import { Platform, ToastAndroid, Alert } from "react-native";

export const isFieldMandatory = async (form, inputField) => {
  try {
    const data = await retrieveData("requiredInputFields");

    // Check if data is not null/undefined and has the requested language key
    if (data && data[form][inputField]) {
      return true;
    } else {
      throw new Error("Data or language not found"); // Force to use DefaultDbLookups
    }
  } catch (error) {
    // if no data was gotten from local storage, then use initial hardcoded db values
    if (defaultRequiredInputFields?.[form]?.[inputField]?.["isMandatory"]) {
      return true;
    } else {
      return false;
    }
  }
};

const getValidations = (field) => {
  if (defaultRequiredInputFields?.[form]?.[inputField]?.["isMandatory"]) {
  } else {
  }
};

export const globalUpdateFormData = (name, value, profile, formValidation, setProfile, setFormValidation) => {
  if (value === null) value = "";
  // let v;
  // if (typeof profile[name] == "object") {
  //   const x = value;
  //   try {
  //     v = x([...profile[name]]);
  //   } catch (e) { }
  // } else {
  //   v = value;
  // }

  // check if isMandatory field is displayed and remove wornings on component
  if (formValidation[name] && formValidation[name]["isMandatory"]) {
    delete formValidation[name]["isMandatory"];
    setFormValidation({ ...formValidation }); // Update state to trigger re-render
  }
  // console.log(profile);
  setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
};

export const customDataValidation = (profile, formName, strings, formValidation, inputField, firstUnValidatedField) => {
  var unValidatedFieldExists = false;

  const validations = defaultRequiredInputFields?.[formName]?.[inputField]?.["validations"];

  if (!validations) return;
  if (!profile[inputField]) return;

  for (const [key, value] of Object.entries(validations)) {
    if (key === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(profile[inputField]);

      if (!isValid) {
        if (!formValidation[inputField]) formValidation[inputField] = {};
        formValidation[inputField]["errors"] = { helperText: strings.dataValidations.helperTexts.invalidEmail };
        unValidatedFieldExists = true;
        setAsFirstUnValidatedField(formValidation, firstUnValidatedField, inputField);
        break;
      }
    }
    if (key === "number") {
      if (isNaN(profile[inputField])) {
        if (!formValidation[inputField]) formValidation[inputField] = {};
        formValidation[inputField]["errors"] = { helperText: strings.dataValidations.helperTexts.invalidNumber };
        unValidatedFieldExists = true;
        setAsFirstUnValidatedField(formValidation, firstUnValidatedField, inputField);
        break;
      }
    }
    if (key === "integer") {
      if (isNaN(profile[inputField]) || profile[inputField].includes(".")) {
        if (!formValidation[inputField]) formValidation[inputField] = {};
        formValidation[inputField]["errors"] = { helperText: strings.dataValidations.helperTexts.invalidNumber };
        unValidatedFieldExists = true;
        setAsFirstUnValidatedField(formValidation, firstUnValidatedField, inputField);
        break;
      }
    }
    if (key === "min") {
      try {
        let num = Number(profile[inputField]);
        let minNum = Number(value);
        if (num < minNum) {
          if (!formValidation[inputField]) formValidation[inputField] = {};
          formValidation[inputField]["errors"] = {
            helperText: `${strings.dataValidations.helperTexts.minNumber} ${minNum}`,
          };
          unValidatedFieldExists = true;
          setAsFirstUnValidatedField(formValidation, firstUnValidatedField, inputField);
          break;
        }
      } catch (e) {
        continue;
      }
    }
    if (key === "max") {
      try {
        let num = Number(profile[inputField]);
        let maxNum = Number(value);
        if (num > maxNum) {
          if (!formValidation[inputField]) formValidation[inputField] = {};
          formValidation[inputField]["errors"] = {
            helperText: `${strings.dataValidations.helperTexts.maxNumber} ${maxNum}`,
          };
          unValidatedFieldExists = true;
          setAsFirstUnValidatedField(formValidation, firstUnValidatedField, inputField);
          break;
        }
      } catch (e) {
        continue;
      }
    }
    if (key === "password") {
      const passwordRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])" + // at least one lowercase, one uppercase, one digit, one non-alphanum character
          "(?=.{8,})" // at least 8 characters
      );
      const missing = [];
      if (!profile[inputField].match(/[a-z]/)) missing.push(strings.dataValidations.helperTexts.passwordALowerCase);
      if (!profile[inputField].match(/[A-Z]/)) missing.push(strings.dataValidations.helperTexts.passwordAUpperCase);
      if (!profile[inputField].match(/[0-9]/)) missing.push(strings.dataValidations.helperTexts.passwordADigit);
      const specialChars = /[^\w]/;
      if (!profile[inputField].match(specialChars)) missing.push(strings.dataValidations.helperTexts.passwordASpecialCharacter);
      if (profile[inputField].length < 8) missing.push(strings.dataValidations.helperTexts.passwordMinLength);

      if (!passwordRegex.test(profile[inputField])) {
        if (!formValidation[inputField]) formValidation[inputField] = {};
        formValidation[inputField]["errors"] = {
          helperText: `${strings.dataValidations.helperTexts.passwordMustContain} \n*${missing.join("\n*")}`,
        };
        unValidatedFieldExists = true;
        setAsFirstUnValidatedField(formValidation, firstUnValidatedField, inputField);
        break;
      }
    }
    if (key === "confirmPassword") {
      if (profile[inputField] !== profile["password"]) {
        if (!formValidation[inputField]) formValidation[inputField] = {};
        formValidation[inputField]["errors"] = {
          helperText: strings.dataValidations.helperTexts.passwordsMustMatch,
        };
        unValidatedFieldExists = true;
        setAsFirstUnValidatedField(formValidation, firstUnValidatedField, inputField);
        break;
      }
    }
  }

  return unValidatedFieldExists;
};

export const handleDataValidation = async (profile, formName, strings, formValidation, setFormValidation) => {
  var unValidatedFieldExists = false;
  var firstUnValidatedField = { value: true };

  // clear any previous form validations first
  formValidation = {};

  for (const [key, value] of Object.entries(profile)) {
    const mandatory = await isFieldMandatory(formName, key);
    if (mandatory) {
      if ((typeof profile[key] === "string" && profile[key] == "") || profile[key].length === 0) {
        if (!formValidation[key]) formValidation[key] = {};
        formValidation[key]["isMandatory"] = true;

        setAsFirstUnValidatedField(formValidation, firstUnValidatedField, key);

        unValidatedFieldExists = true;
      } else formValidation[key] = false;
    }

    // now perform any more required custom validations
    if (customDataValidation(profile, formName, strings, formValidation, key, firstUnValidatedField)) unValidatedFieldExists = true;
  }

  setFormValidation({ ...formValidation }); // Update state to trigger re-render
  if (unValidatedFieldExists) {
    // if (Platform.OS === "android") {
    //   ToastAndroid.show(strings.dataValidations.checkFormErrors, ToastAndroid.SHORT);
    // } else {
    //   Alert.alert("", strings.dataValidations.checkFormErrors);
    // }
  }
  return !unValidatedFieldExists;
};

const setAsFirstUnValidatedField = (formValidation, firstUnValidatedField, key) => {
  if (firstUnValidatedField.value) {
    // console.log("setting firstUnValidatedField", key);
    firstUnValidatedField.value = false;
    formValidation[key]["scrollToComponent"] = true;
  }
};

export const scrollToComponent = (formValidation, scrollViewRef, componentRef, scrollViewHeight) => {
  if (formValidation?.["scrollToComponent"] && componentRef?.current) {
    // console.log("scrolling to component", formValidation);
    formValidation["scrollToComponent"] = false;
    componentRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }
};
