import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MySelectList from "../MyInputs/MySelectList";
import MyMultipleSelectList from "../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addArrest, getArrests, updateArrest } from "../../api/api";
import MyDatePickerInput from "../MyInputs/MyDatePicker";
import MyRadioGroup from "../MyInputs/MyRadioGroup";
import MyYesNoRadioGroup from "../MyInputs/MyYesNoRadioGroup";
import { Grid } from "@mui/material";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";

const MyInvestigationsForm = ({ d }) => {
  const formName = "TipArrestForm";
  const parentFormName = "VictimProfileForm";

  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        case_id: "",
        suspectArrested: "",
        organizedCrime: "",
        whyNoArrest: "",
        victimSmuggled: "",
        howTraffickersOrg: [],
        investigationDone: "",
        whyNoInvestigation: "",
        investigationStatus: "",
        whyPending: "",
        withdrawnClosedReason: "",
      })
    }

    d.setStrings(strings.arrest)
    d.setFormName(formName)
    d.setParentFormName(parentFormName)
    d.setIsProfileSet(true);
    d.setGetFormData(() => getArrests)
    d.setPutFormData(() => updateArrest)
    d.setPostFormData(() => addArrest)

    d.setIsFirstForm(false);
    d.setRequiredId("case_id");

  }, []);

  // Effect to update profile.v_id whenever victimId changes
  useEffect(() => {
    // console.log("transist form victimId updating to ", d.victimId);
    d.setProfile((prevProfile) => ({
      ...prevProfile,
      case_id: d.caseId,
    }));
  }, [d.caseId]);


  if (!d.isProfileSet) return <div></div>

  return (
    <div>
      <MyCard>
        <Grid container>
          <MyFormsGridItem>
            <MyYesNoRadioGroup d={{ name: "suspectArrested", ...d }} />
            {d.profile.suspectArrested === false ? (
              <MyTextInput d={{ name: "whyNoArrest", ...d }} multiline rows={3} />
            ) : (
              <></>
            )}
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyYesNoRadioGroup d={{ name: "organizedCrime", ...d }} />
          
          </MyFormsGridItem>
        </Grid>


      </MyCard>
      <MyCard>
        <Grid container>
          <MyFormsGridItem>
            <MyYesNoRadioGroup d={{ name: "victimSmuggled", ...d }} />
            <MyYesNoRadioGroup d={{ name: "investigationDone", ...d }} />

            {d.profile.investigationDone === false ? (
              <MyTextInput d={{ name: "whyNoInvestigation", ...d }} />
            ) : d.profile.investigationDone === true ? (
              <>
                <MySelectList
                  d={{ name: "investigationStatus", ...d, dbLookupName: "investigationStatuses" }}
                />
                {d.profile.investigationStatus === d.dbLookups.investigationStatuses?.[2]?.["key"] ||
                  d.profile.investigationStatus === d.dbLookups.investigationStatuses?.[3]?.["key"] ? (
                  <MyTextInput d={{ name: "withdrawnClosedReason", ...d }} />
                ) : null}
                {d.profile.investigationStatus === d.dbLookups.investigationStatuses?.[0]?.["key"] ? (
                  <MyTextInput d={{ name: "whyPending", ...d }} />
                ) : null}
              </>
            ) : null}
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyMultipleSelectList d={{ name: "howTraffickersOrg", ...d, dbLookupName: "traffickerOrgs" }} />
            </MyFormsGridItem>
        </Grid>


      </MyCard>
    </div>
  );
};

export default MyInvestigationsForm;
