import React from 'react';
import { LinearProgress } from '@mui/material';

const MyLoadingBar = ({ loading }) => {
  return (
    loading ? (
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1500 }}>
        <LinearProgress />
      </div>

    ) : (null)
  );
};

export default MyLoadingBar;
