import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Menu from "./navigation/Menu";
import MyVictimProfileForm from "./components/MyForms/MyVictimProfileForm";
import defaultDbLookups from "./i18n/db-lookups/defaultDbLookups";
import fr from "./i18n/fr";
import en from "./i18n/en";
import pt from "./i18n/pt";
import MyGeneralForm from "./components/MyForms/MyGeneralForm";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import materialTheme from "./utils/materialTheme";
import TipCasesScreen from "./screens/TipCasesScreen";
import { navigationRoutes } from "./utils/variables";
import TipCasesAddScreen from "./screens/TipCasesAddScreen";
import LoginScreen from "./screens/LoginScreen";
import { retrieveDbLookup, retrieveFindHelps } from "./utils/updateDbLookups";
import { retrieveData, storeData } from "./utils/offlineStorage";
import { checkAuth, signout } from "./api/auth";
import FindHelpScreen from "./screens/FindHelpScreen";
import SettingsScreen from "./screens/SettingsScreen";
import SomCasesScreen from "./screens/SomCasesScreen";
import SomCasesAddScreen from "./screens/SomCasesAddScreen";
import DashboardScreen from "./screens/DashboardScreen";
import MyVictimSearchForm from "./components/searchForms/MyVictimSearchForm";
import SearchScreen from "./screens/SearchScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";

const AuthContext = React.createContext();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [interviewer, setInterviewer] = useState(null);
  const [strings, setStrings] = useState(en);
  const [language, setLanguage] = useState("en");
  const [dbLookups, setDbLookups] = useState(defaultDbLookups.dbLookups.en);
  const [findHelps, setFindHelps] = useState(defaultDbLookups.findHelps.en);
  const [victimId, setVictimId] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [globalAppVariables, setGlobalAppVariables] = useState({});

  const location = useLocation();

  // const [appState, setAppState] = useState(AppState.currentState);
  const [shouldAuthenticate, setShouldAuthenticate] = useState(true);
  // const appState = useRef(AppState.currentState);
  let appStateVisible = true;
  const setAppLanguage = async (val) => {
    // make sure val is not null
    if (!val) return;

    setLanguage(val);
    const dbLookup = await retrieveDbLookup(val);
    const findHelp = await retrieveFindHelps(val);
    setDbLookups(dbLookup);
    setFindHelps(findHelp);

    if (val == "en") {
      await storeData("app_lang", "en");
      setStrings(en);
    } else if (val == "fr") {
      await storeData("app_lang", "fr");
      setStrings(fr);
    } else if (val == "pt") {
      await storeData("app_lang", "pt");
      setStrings(pt);
    }
  };

  const logout = async () => {
    // Perform the logout operation (clear tokens, update state, etc.)
    await signout();
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const updateAppData = async () => {
      const lang = await retrieveData("app_lang");
      await setAppLanguage(lang);
      const { auth, interviewer } = await checkAuth();
      //console.log(auth);
      setIsAuthenticated(auth);
      setInterviewer(interviewer);
    };

    updateAppData();
  }, []);

  const About = () => <div>About Page</div>;

  return (
    <ThemeProvider theme={materialTheme}>
      <CssBaseline />
      <AuthContext.Provider
        value={{
          strings,
          language,
          setAppLanguage,
          dbLookups,
          findHelps,
          isAuthenticated,
          setIsAuthenticated,
          interviewer,
          setInterviewer,
          victimId,
          setVictimId,
          caseId,
          setCaseId,
          globalAppVariables,
          setGlobalAppVariables,
        }}
      >
        <div style={{ display: "flex" }}>
          {isAuthenticated === null ? (
            <></>
          ) : isAuthenticated === true ? (
            <Menu logout={logout} />
          ) : location.pathname === navigationRoutes.register ? (
            <Navigate to={navigationRoutes.register} />
          ) : location.pathname === navigationRoutes.forgotPassword ? (
            <Navigate to={navigationRoutes.forgotPassword} />
          ) : (
            <Navigate to={navigationRoutes.login} />
          )}
          <Routes>
            <Route path={navigationRoutes.login} element={<LoginScreen />} />
            <Route path={navigationRoutes.register} element={<RegisterScreen />} />
            <Route path={navigationRoutes.forgotPassword} element={<ForgotPasswordScreen />} />

            {isAuthenticated ? (
              <>
                <Route path={navigationRoutes.home} element={<Navigate to={navigationRoutes.dashboard} />} />
                <Route path={navigationRoutes.dashboard} element={<DashboardScreen />} />
                <Route path={navigationRoutes.tipCases} element={<TipCasesScreen />} />
                <Route path={navigationRoutes.tipCasesNew} element={<TipCasesAddScreen />} />
                <Route path={navigationRoutes.somCases} element={<SomCasesScreen />} />
                <Route path={navigationRoutes.somCasesNew} element={<SomCasesAddScreen />} />

                <Route path={navigationRoutes.search} element={<SearchScreen />} />
                <Route path={navigationRoutes.findHelp} element={<FindHelpScreen />} />

                <Route path={navigationRoutes.profile} element={<About />} />
                <Route path={navigationRoutes.settings} element={<SettingsScreen />} />
              </>
            ) : (
              <></>
            )}
          </Routes>
        </div>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
export { AuthContext };
