import React, { useContext } from "react";
import { Card, CardContent, CardHeader, Avatar, Typography, Box } from '@mui/material';
import { AuthContext } from "../App";
import styles from "./MyInputs/styles";


const MyDashboardCard = ({ title, count, Icon }) => {

  return (
    <Card sx={styles.dashboardCard}>
      <Box>
        <Typography variant="h5">{title}</Typography>
        <Typography>{count}</Typography>

      </Box>
      <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} aria-label="">
        {React.cloneElement(Icon, { fontSize: 'large' })}
      </Avatar>
    </Card>

  )
};

export default MyDashboardCard;
