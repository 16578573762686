// screens/LoginScreen.js
import React, { useContext, useState } from "react";
import { authenticateUser } from "../api/auth";
import { theme, navigationRoutes } from "../utils/variables";
import { AuthContext } from "../App";
import { Autocomplete, Box, Button, Container, Grid, ImageListItem } from "@mui/material";
import styles from "../components/MyInputs/styles";
import MySnackBar from "../components/MyInputs/MySnackBar";
import imageLogo from "../assets/icon.png";
import MyCard from "../components/MyInputs/MyCard";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Lock, Login, PersonAdd } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MyCardTitle from "../components/MyInputs/MyCardTitle";
import MySelectList from "../components/MyInputs/MySelectList";
import { useNavigate } from "react-router-dom";
import MyLoadingBar from "../components/MyLoadingBar";
import MyTextInput from "../components/MyInputs/MyTextInput";
import MyFormsGridItem from "../components/MyInputs/MyFormsGridItem";
import MyGeneralForm from "../components/MyForms/MyGeneralForm";
import MyRegistrationForm from "../components/MyForms/MyRegistrationForm";
import MyRegistrationSuccessDialog from "../components/MyRegistrationSuccessDialog";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_KEY } from "../api/api";

const RegisterScreen = ({ navigation }) => {
  const { strings, setAppLanguage, setInterviewer, setIsAuthenticated } = useContext(AuthContext);
  const [formsUploaded, setFormsUploaded] = useState({});
  const [language, setLanguage] = useState("en");
  const [isReCaptchaSet, setIsReCaptchaSet] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
    navigate(navigationRoutes.login);
  };

  const handleLogoutClose = () => {
    setLogoutDialogOpen(false);
  };

  const onDismissProgressBar = () => {
    setProgressBarVisible(false);
  };

  const navigate = useNavigate();
  const goToLogin = () => {
    setLogoutDialogOpen(true);
  };

  const showSnackBar = (message, severity) => {
    setProgressBarMessage(message);
    setProgressBarSeverity(severity);
    setProgressBarVisible(true);
  };

  const data = {
    isCreateMode: true,
    formsUploaded: formsUploaded,
    setFormsUploaded: setFormsUploaded,
    showSnackBar: showSnackBar,
  };

  return (
    <Container maxWidth="lg">
      <MySnackBar message={progressBarMessage} severity={progressBarSeverity} visible={progressBarVisible} onClose={onDismissProgressBar} />
      <MyRegistrationSuccessDialog open={logoutDialogOpen} onClose={handleLogoutClose} onConfirm={handleLogoutConfirm} />
      <MyGeneralForm onNext={goToLogin} FormFields={MyRegistrationForm} d={{ ...data, formIndex: 1, setCaptcha: true }} />
    </Container>
  );
};

export default RegisterScreen;
