import React, { useState, useEffect, useContext } from "react";
import MyTextInput from "./MyTextInput";
import styles from "./styles";
import { AuthContext } from "../../App";
import { Typography } from "@mui/material";

const MyMultipleTextInput = ({ d, overrideOptions, ...otherProps }) => {
  var dbLookupName = d.dbLookupName || d.name;
  var stringName = d.stringName || d.name;

  const [isTotalCountError, setIsTotalCountError] = useState(false);
  const { strings } = useContext(AuthContext);

  const localUpdateFormData = (name, value) => {
    d.handleNestedChange(d.name, name, value);
  };

  const totalEnteredVictims = () => {
    let total = 0;
    const victims = d.profile[d.name];

    if (victims && typeof victims === "object") {
      Object.values(victims).forEach((value) => {
        total += Number(value);
      });
    }

    return total;
  };

  useEffect(() => {
    // console.log(d.profile[d.name]);
    if (totalEnteredVictims() > d.profile["numberOfVictims"]) {
      setIsTotalCountError(true);
    } else {
      setIsTotalCountError(false);
    }
  }, [d.profile[d.name], d.profile["numberOfVictims"]]);

  var options;
  if (overrideOptions) options = overrideOptions;
  else options = d.dbLookups[dbLookupName];

  return (
    <div style={{ ...(d.noStyling ? {} : styles.mySelectList), ...(isTotalCountError ? styles.mySelectListErrors : {}) }} >

      <Typography style={styles.MyTextLabel}>{d.strings[stringName]}</Typography>
      {isTotalCountError ? (
        <Typography style={styles.MyTextLabelError}>* {strings.dataValidations.helperTexts.totalCountError}</Typography>
      ) : (
        <></>
      )}
      {options?.map((item) => (
        <MyTextInput
          key={item.key}
          d={{
            ...d,
            updateFormData: localUpdateFormData,
            name: item.value,
            overrideValue: d.profile[d.name][item.value],
          }}
          overrideLabel={item.value}
          inputMode="number"
          inputProps={{ min: 0 }}
        />
      ))}
    </div>
  );
};

export default MyMultipleTextInput;
