import React, { useContext } from "react";
import { Card, CardContent, CardActions, Typography, Divider, IconButton, CardActionArea, CardHeader, Avatar, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Person, RemoveRedEye } from '@mui/icons-material';
import styles from './MyInputs/styles';
import { AuthContext } from "../App";
import { darken, lighten } from '@mui/system'; // Import the darken function
import { useTheme } from '@mui/material/styles';
import imageLogo from "../assets/icon.png";

function getFlagEmoji(countryCode) {
  try {
    if (countryCode.length !== 2) {
      return '';
    }

    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + char.charCodeAt());

    return String.fromCodePoint(...codePoints);
  } catch (e) {
    console.log(e);
    return '';
  }

}

const MyUserProfileCard = () => {
  const { interviewer, dbLookups } = useContext(AuthContext);
  const theme = useTheme();


  return (
    // <Card sx={{   backgroundImage: `url(${imageLogo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', color: 'primary.contrastText', p: 1 }}>
    <Card sx={{  backgroundColor: darken(theme.palette.primary.main, 0.1), color: 'primary.contrastText', p: 1, margin: '0 4px', marginBottom: '8px', borderRadius: '18px' }}>
      

      <CardHeader
          avatar={
            <Avatar sx={{ backgroundColor: darken(theme.palette.primary.main, 0.2), color: 'secondary.main', }}>
              {/* {getFlagEmoji(interviewer.country?.two_code)} */}
              <Person />
            </Avatar>
          }
          titleTypographyProps={{ sx: { color: 'primary.contrastText' } }}
          subheaderTypographyProps={{ sx: { color: 'primary.contrastText' } }}
          title={`${interviewer.first_name} ${interviewer.last_name}`}
          subheader={`${interviewer.country?.name}`}
          sx={{ p: 0 }} // Removes padding from CardHeader
        />
        <CardContent
          sx={{ p: 0,
            '&:last-child': {
            paddingBottom: '4px',
          } 
        }} // Removes padding from CardHeader

        >
          <Typography variant="caption" sx={{ color: 'primary.contrastText' }}>
            {dbLookups.dataEntryPurposes.find(purpose => purpose.key == interviewer.data_entry_purpose?.id)?.value}
            {/* {interviewer.data_entry_purpose?.name} */}
          </Typography>
        </CardContent>

    </Card>
  );
};

export default MyUserProfileCard;
