import { matchProfileVariablesToServerColumns } from "./variables";

export const columnNameToReadableFormat = (columnName) => {
  if (!columnName) return "";

  // Convert camelCase to Title Case
  let formattedName = columnName.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Convert snake_case to Title Case
  formattedName = formattedName.replace(/_/g, " ");

  // Capitalize the first letter of each word
  formattedName = formattedName.replace(/\b\w/g, (char) => char.toUpperCase());

  return formattedName;
};

export const getProfileFromJson = (profile, data, formName) => {
  const updatedProfile = { ...profile };
  const mapping = matchProfileVariablesToServerColumns[formName];

  try {
    Object.keys(mapping).forEach((profileKey) => {
      const dataKey = mapping[profileKey];
      const value = data[dataKey];

      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          // Map array of objects to array of ids and convert to strings if numbers
          updatedProfile[profileKey] = value.map((item) =>
            typeof item === "number" ? String(item) : item.id ? String(item.id) : item
          );
        } else if (typeof value === "object") {
          // If value is an object with an id property, convert id to string if number
          if(value.id){
            updatedProfile[profileKey] = typeof value.id === "number" ? String(value.id) : value.id;
          }else{
            updatedProfile[profileKey] = value;
          }
            

        } else if (typeof value === "number") {
          // Convert number to string
          updatedProfile[profileKey] = String(value);
        // } else if (typeof value === "boolean") {
        //   if (value === true) updatedProfile[profileKey] = "1";
        //   if (value === false) updatedProfile[profileKey] = "0";
        } else {
          // Non-array and non-object case: directly assign the value
          updatedProfile[profileKey] = value;
        }
      }
    });
  } catch (e) {
    console.error(e);
    return profile;
  }

  return updatedProfile;
};

export const getJsonFromProfile = (profile, formName) => {
  const updatedProfile = {};
  const mapping = matchProfileVariablesToServerColumns[formName];

  Object.keys(profile).forEach((camelCaseKey) => {
    const value = profile[camelCaseKey] === "" ? null : profile[camelCaseKey];

    if (mapping.hasOwnProperty(camelCaseKey)) {
      const snakeCaseKey = mapping[camelCaseKey];
      updatedProfile[snakeCaseKey] = value;
    } else {
      updatedProfile[camelCaseKey] = value;
    }
  });


  return updatedProfile;
};
